import { List, ListItem, ListItemText, Box, Button, Chip, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, Modal } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { getNameById, handlingAfterFetch } from '../../helpers/Tools';
import AppContext from '../../contexts/AppContext';
import { useUIContext } from '../../contexts/UIContext';
import { useSegmentsContext } from '../../contexts/SegmentsContext';
import { Form } from '../../styles/AuthenticationStyles';
import { getCountriesByContinent } from '../../api/externalApi';
import theme from '../../styles/CustomTheme';
import LanguagesSelector from '../LanguagesSelector';
import accountImg from '../../assets/images/Account.gif'; 
import Loader from '../Loader';
import { editUser } from '../../api/usersApi';
import { SUCCESS_ALERT_OBJECT, ERROR_ALERT_OBJECT } from "../../helpers/AlertPopup";
import customTranslations from '../../data/staticTranslations.json';
import { useCustomerContext } from '../../contexts/CustomerContext';
import {MANAGER_PERMISSIONS, USER_PERMISSIONS} from '../../helpers';
import { modalStyle } from '../../styles/ModalStyles';
import ChangeEmailModal from '../ChangeEmailModal';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function MyAccount({screen}) {
    const {loggedInUser, setLoggedInUser, companies} = useContext(AppContext);
    const {customerLanguage, systemMessages} = useCustomerContext();
    const {setAlertObject, setDialogObject} = useUIContext();
    const {segments} = useSegmentsContext();

    const continents = [ 
        { 
            name: "Asia", 
            label_en: "Asia",
            label_es: "Asia" 
        },
        { 
            name: "Africa", 
            label_en: "Africa",
            label_es: "África" 
        },
        { 
            name: "Oceania", 
            label_en: "Australia and Oceania",
            label_es: "Australia y Oceanía"
        },
        { 
            name: "Europe", 
            label_en: "Europe",
            label_es: "Europa"
        },
        { 
            name: "North America", 
            label_en: "North America",
            label_es: "América del norte"
        },
        { 
            name: "South America", 
            label_en: "South America",
            label_es: "Sudamerica"
        },
        { 
            name: "Antarctica", 
            label_en: "Antarctica",
            label_es: "Antártida"
        },
    ];

    const [fullName, setFullName] = useState(loggedInUser.fullName || '');
    const [email, setEmail] = useState(loggedInUser.email || '');
    const [companyId, setCompanyId] = useState(loggedInUser.companyId || 0);
    const [chosenSegments, setChosenSegments] = useState(
        loggedInUser.permissions === USER_PERMISSIONS ?
        loggedInUser?.segments || [] :
        loggedInUser?.ManagerAssignment?.segmentId ? [loggedInUser?.ManagerAssignment?.segmentId] : []
    );
    const [region, setRegion] = useState(
        loggedInUser.permissions === USER_PERMISSIONS ?
        loggedInUser.region || '' :
        loggedInUser.ManagerAssignment.region
    );
    const [country, setCountry] = useState(loggedInUser.country || '');
    const [address, setAddress] = useState(loggedInUser.address || '');
    const [phone, setPhone] = useState(loggedInUser.phone || '');
    const [countriesList, setCountriesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [callingCode, setCallingCode] = useState(loggedInUser.phone ? '' : '+');
    const [languageId, setLanguageId] = useState(loggedInUser.languageId || 1);
    const [isModalOpen, setIsModalOpen] = useState(false);

    //for manager only
    const [assignedCountries, setAssignedCountries] = useState(loggedInUser?.ManagerAssignment?.countries || [])

    const pageContent = customTranslations.items.filter(item=> item.location === screen).reduce((result, item) => {
        const { item: itemName, name_en, name_es, list_items, steps } = item;
        result[itemName] = { name_en, name_es, list_items, steps };
        return result;
    }, {});

    const getStyles = (option, attributeSegments, theme) => {
        return {
            fontWeight:
                attributeSegments?.indexOf(option) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightSemibold,
        };
    };

    const handleEditUser = async(e) => { 
        e.preventDefault();

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (email !== loggedInUser?.email && !emailRegex.test(email)) {
            // Email is not valid, show an alert
            setAlertObject({...ERROR_ALERT_OBJECT, message: `Please enter a valid email address.`});
            return;
        } else if(email !== loggedInUser?.email){
            const logoutConfig = {
                open: true, 
                ok: pageContent.proceed_btn[`name_${customerLanguage.languageCode}`], 
                cancel: pageContent.cancel_btn[`name_${customerLanguage.languageCode}`], 
                title: (
                    <Stack alignItems={'center'} direction={'row'} gap={'10px'}>
                        <WarningRoundedIcon color={'secondary'} fontSize={'large'}/>
                        {pageContent.email_change_title[`name_${customerLanguage.languageCode}`]}
                    </Stack>
                ), 
                description: pageContent.email_change_description[`name_${customerLanguage.languageCode}`],
                content: (
                    <List sx={{listStyleType: 'disc', paddingInlineStart: '20px'}}>
                        {pageContent.email_change_description.list_items?.map(item => (
                            <ListItem sx={{paddingBlock: 0, display: 'list-item'}}>
                                <ListItemText>{item[`name_${customerLanguage.languageCode}`]}</ListItemText> 
                            </ListItem>
                        ))}
                    </List> 
                ),
                call_on_submit: () => setIsModalOpen(true)
            };
    
            setDialogObject(logoutConfig);
            return;
        }

        const data = {
            id: loggedInUser.id,
            fullName,
            permissions: loggedInUser.permissions,
            companyId: companyId === 0 ? null : companyId,
            country,
            phone: phone === loggedInUser.phone ? loggedInUser.phone : callingCode + phone,
            address,
            region,
            segments: chosenSegments,
            languageId,
        };

        setIsLoader(true);
        await handlingAfterFetch( 
            editUser(data), 
            (res) => {
                if (res.success && res.data) {
                    setLoggedInUser({...res.data});
                    setAlertObject({...SUCCESS_ALERT_OBJECT, message: systemMessages?.data_updated[`name_${customerLanguage.languageCode}`]})
                }
            },
            setAlertObject,
        );
        setIsLoader(false);
    }

    const handleChangeMultipleSelect = (event, stateSetter) => {
        const {
            target: { value },
        } = event;
        stateSetter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleSetRegion = async(value) => {
        setRegion(value);
        setCountry('');
        setPhone('');
        setLoading(true);
        await handlingAfterFetch( 
            getCountriesByContinent(value), 
            (res) => {
                if(res?.success && res?.data) setCountriesList(
                    res.data
                      ?.filter(item => item?.independent)
                      .sort((a, b) => a?.name?.common?.localeCompare(b?.name?.common))
                ); 
            },
            setAlertObject,
        );
        setLoading(false);
    }

    const handleGetRegionCountries = async(region) => {
        setLoading(true);
        await handlingAfterFetch( 
            getCountriesByContinent(region), 
            (res) => {
                if(res?.success && res?.data) setCountriesList(
                    res.data
                      ?.filter(item => item?.independent)
                      .sort((a, b) => a?.name?.common?.localeCompare(b?.name?.common))
                ); 
                setCountry(res?.data.find(country=>country.name?.common === loggedInUser.country)?.name.common);
            },
            setAlertObject,
        );
        setLoading(false);
    }

    const handleBeforeCloseModal = () => {
        const closeModalConfig = {
            open: true, 
            ok: "CLOSE", 
			cancel:"CANCEL",
            title:`Want to close modal?`, 
            description: "Data will not be saved",
            content: null,
            call_on_submit: () => setIsModalOpen(false)
        };

        setDialogObject(closeModalConfig);      
    }

    useEffect(() => {
      if(!loggedInUser.region) return;
      handleGetRegionCountries(loggedInUser.region);
    }, [])
    
    return (
        <>
            <Stack 
                direction={'column'} 
                sx={{
                    width: '100%', 
                    height: 'calc(100vh - max(60px, 6vw))',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {isLoader ?
                    <Loader/>
                    :
                    <>
                        <Typography component={'h1'} variant={'h2'} mb={'max(30px, 3vmax)'}> {pageContent?.title[`name_${customerLanguage.languageCode}`]} </Typography>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Box width={'50%'} sx={{mt: loggedInUser.permissions === MANAGER_PERMISSIONS ? 'max(30px, 5vmax)' : 0}}>
                                {loggedInUser.permissions === USER_PERMISSIONS && 
                                    <LanguagesSelector 
                                        languageId={languageId} 
                                        setLanguageId={setLanguageId} 
                                        sx={{mb: 'max(20px, 1.4vw)', width: '100%'}} 
                                        size={'medium'}
                                        label={pageContent?.input_language[`name_${customerLanguage.languageCode}`]}
                                    />
                                }
                                <Form direction={'row'} onSubmit={handleEditUser} autoComplete="off" gap={'max(20px, 1.4vw)'}>
                                    <TextField
                                        required
                                        id="fullname"
                                        label={pageContent?.input_fullname[`name_${customerLanguage.languageCode}`]}
                                        defaultValue={fullName}
                                        onChange={(e)=>setFullName(e.target.value)}
                                        autoComplete='ViewCrunch'
                                        sx={{width: '48%'}}
                                    />
                                    <TextField
                                        required
                                        id="email"
                                        label={pageContent?.input_email[`name_${customerLanguage.languageCode}`]}
                                        defaultValue={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                        autoComplete='ViewCrunch'
                                        sx={{width: '48%'}}
                                    />
                                    <FormControl sx={{width: '48%'}}>
                                        <InputLabel id="company-select-label">{pageContent?.input_company[`name_${customerLanguage.languageCode}`]} *</InputLabel>
                                        <Select
                                            required
                                            labelId="company-select-label"
                                            id="company-select"
                                            value={companyId}
                                            label={`${pageContent?.[`name_${customerLanguage.languageCode}`]} *`}
                                            onChange={(e)=>setCompanyId(e.target.value)}
                                            disabled
                                            // disabled={loggedInUser.permissions === MANAGER_PERMISSIONS}
                                        >
                                            {
                                                companies?.length > 0 && companies?.map(comp=>(
                                                    <MenuItem value={comp?.id}>{comp?.companyName}</MenuItem>
                                                ))
                                            }
                                                <MenuItem value={0}>{customerLanguage.languageCode === 'en' ? 'Other' : 'Otra'}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{width: '48%'}}  >
                                        <InputLabel id="segments-label">
                                            {   
                                                loggedInUser.permissions === USER_PERMISSIONS ? 
                                                pageContent?.input_segments[`name_${customerLanguage.languageCode}`] + '*'
                                                :
                                                `Assigned Segment`
                                            } 
                                        </InputLabel>
                                        <Select
                                            labelId="segments-label"
                                            id="segments-chip"
                                            multiple
                                            value={chosenSegments}
                                            onChange={(e) => handleChangeMultipleSelect(e, setChosenSegments)}
                                            input={
                                                <OutlinedInput 
                                                    id="segments" 
                                                    label={loggedInUser.permissions === USER_PERMISSIONS ? 
                                                        pageContent?.input_segments[`name_${customerLanguage.languageCode}`] + '*'
                                                        :
                                                        `Assigned Segment`
                                                    } 
                                                    required
                                                />
                                            }
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={
                                                        languageId === 1 ? 
                                                            getNameById(value, 'name', segments)
                                                        :
                                                        segments.find(segment=> +segment.id === +value).translations.find(item=> +item.entityId === +value)?.attributeValue || getNameById(value, 'name', segments)
                                                    }/>
                                                ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                            disabled={loggedInUser.permissions === MANAGER_PERMISSIONS}
                                        >
                                            {segments.map((segment) => (
                                                <MenuItem
                                                    key={segment.id}
                                                    value={segment.id}
                                                    style={getStyles(segment.id, chosenSegments, theme)}
                                                >
                                                    {segment.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{width: '48%'}}  >
                                        <InputLabel id="region-select-label">
                                            {   
                                                loggedInUser.permissions === USER_PERMISSIONS ? 
                                                pageContent.input_region[`name_${customerLanguage.languageCode}`] + '*'
                                                :
                                                `Assigned Region`
                                            } 
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="region-select-label"
                                            id="region-select"
                                            value={region}
                                            label={
                                                loggedInUser.permissions === USER_PERMISSIONS ? 
                                                pageContent.input_region[`name_${customerLanguage.languageCode}`] + '*'
                                                :
                                                `Assigned Region`
                                            }
                                            onChange={(e)=>handleSetRegion(e.target.value)}
                                            disabled
                                            // disabled={loggedInUser.permissions === MANAGER_PERMISSIONS}
                                        >
                                            {continents.map(continent=>(
                                                <MenuItem value={continent.name}>{continent[`label_${customerLanguage.languageCode}`]}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> 
                                    <FormControl sx={{width: '48%'}}>
                                        {
                                            loggedInUser.permissions === USER_PERMISSIONS && 
                                            <>
                                                {loading ? 
                                                    <Stack justifyContent={'center'} alignItems={'center'}>
                                                        <CircularProgress color="secondary" /> 
                                                    </Stack>
                                                    :
                                                    <>
                                                        <InputLabel disabled={!country} id="country-select-label"> {pageContent.input_country[`name_${customerLanguage.languageCode}`]} * </InputLabel>
                                                        <Select
                                                            required
                                                            labelId="country-select-label"
                                                            id="country-select"
                                                            value={country}
                                                            label={`${pageContent.input_country[`name_${customerLanguage.languageCode}`]} *`}
                                                            onChange={(e)=>{setCountry(e.target.value); setPhone('')}}
                                                            disabled
                                                            // disabled={!country}
                                                        >
                                                            {countriesList.map(countryItem=>( 
                                                                <MenuItem value={countryItem.name.common} onClick={()=>setCallingCode(countryItem.idd.root +countryItem.idd.suffixes.join(''))}>{countryItem.name.common}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </>
                                                }
                                            </>
                                        }
                                        {
                                            loggedInUser.permissions === MANAGER_PERMISSIONS && 
                                            <>
                                                <InputLabel id="assigned-countries-label">
                                                    {   
                                                        `Assigned Countries`
                                                    } 
                                                </InputLabel>
                                                <Select
                                                    labelId="assigned-countries-label"
                                                    id="assigned-countries-chip"
                                                    multiple
                                                    value={assignedCountries}
                                                    onChange={(e) => handleChangeMultipleSelect(e, setAssignedCountries)}
                                                    input={
                                                        <OutlinedInput 
                                                            id="assigned-countries" 
                                                            label={
                                                                `Assigned Countries`
                                                            }
                                                            required
                                                        />
                                                    }
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value}/>
                                                        ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    disabled
                                                />
                                            </>
                                        }
                                    </FormControl> 
                                    {   loggedInUser.permissions === USER_PERMISSIONS &&
                                        <>
                                            <TextField
                                                required
                                                id="address"
                                                label={pageContent.input_address[`name_${customerLanguage.languageCode}`]}
                                                defaultValue={address}
                                                onChange={(e)=>setAddress(e.target.value)}
                                                autoComplete='ViewCrunch'
                                                sx={{width: '48%'}}
                                            />
                                            <TextField
                                                required
                                                id="phone"
                                                label={pageContent.input_phone[`name_${customerLanguage.languageCode}`]}
                                                placeholder={country === '' ? 'Choose country to set phone' : null}
                                                value={phone}
                                                onChange={(e)=>setPhone(e.target.value)}
                                                autoComplete='ViewCrunch'
                                                disabled={country === ''}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{callingCode}</InputAdornment>,
                                                }}
                                                sx={{width: '48%'}}
                                            />
                                        </>
                                    }
                                    <Button
                                        variant="contained"
                                        color={'secondary'}
                                        type={'submit'}
                                        fullWidth
                                    >
                                        {pageContent.save_btn[`name_${customerLanguage.languageCode}`]}
                                    </Button>
                                </Form>
                            </Box>
                            <Stack 
                                direction={'row'} 
                                justifyContent={'center'} 
                                alignItems={'end'}
                                sx={{mt: 'max(30px, 5vh)', width: '45%'}}
                            >
                                <img src={accountImg} alt={'Dorot Configurator'}/>
                            </Stack>
                        </Stack>
                    </>
                }
            </Stack>
            <Modal
				open={isModalOpen}
				onClose={handleBeforeCloseModal}
			>
				<Box sx={{...modalStyle, maxHeight: '40vh'}}>
					<ChangeEmailModal
                        content={pageContent.email_verification_modal}
						setIsModalOpen={setIsModalOpen}
                        email={email}
					/>
				</Box>
			</Modal>
        </>
    )
}

export default MyAccount