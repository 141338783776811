export const modalStyle = {
	position: 'absolute',
	inset: 0,
	margin: 'auto',
	width: 'min(90%, 800px)',
	borderRadius: 'max(16px, 1.5vw)',
	bgcolor: '#f5f5f5',
	boxShadow: `none !important`,
	p: 5,
	maxHeight: '90vh',
	overflowY: 'auto',
	outline: 'none'
};