import styled from "@emotion/styled";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { Box, Stack, Switch } from "@mui/material";
import esFlag from '../assets/images/flags/es.png';
import enFlag from '../assets/images/flags/en.png';

const drawerWidth = 'max(270px, 15vw)';

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    background: theme.palette.primary.dark,
});
  
const closedMixin = (theme) =>{ 
    return ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    background: theme.palette.primary.dark,
    width: `calc(${theme.spacing(8)} + 1px)`,
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    // },
})};

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

export const SubMenuStyle = (isOpen) => ({
    maxHeight: isOpen ? '200px' : '0px',
    overflow: 'hidden',
    padding: 0,
    background: `rgba(0,0,0, ${isOpen ? '0.15' : '0'})`,
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px 8px 8px 20px',
}));
  
export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

export const ListItemStyles = (theme, isActive) => ({
    minHeight: 48,
    background: isActive ? `rgba(255, 255, 255, 0.15) !important` : 'transparent',
    px: 2.5,
    color: theme.palette.gray[200],
    'svg':{
        fill: theme.palette.gray[200],
    },
    '&.sub-item': {
        minHeight: 30,
        borderRadius: '10px',
        padding: '4px 10px',
        'span': {
            fontSize: '0.9rem'
        }
    }
});

export const dashboardContainer = (theme, isNavOpen, activeScreen) => ({
    minHeight: '100vh',
    marginInlineStart: isNavOpen ? 'max(270px, 15vw)' : theme.spacing(5),
    padding: activeScreen === '' ? 'max(20px, 1.6vw) max(20px, 2vw)' : 'max(30px, 3vw) max(20px, 3vw)',
    background: theme.palette.gray[200],
});

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 52,
    height: 28,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `
                    url('${enFlag}')
                `,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: 'transparent',
        width: 24,
        height: 24,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.8)',
            borderRadius: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url('${esFlag}')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

export const InitialsBox = styled(Stack)(({ theme, open, background }) => ({
    width: open ? '48px' : '30px',
    aspectRatio: 1,
    background: background || '#ff8f94',
    borderRadius: '50%',
    transition: 'all ease-out 200ms',
    color: theme.palette.primary.dark,
    boxShadow: `0px 1px 4px 2px rgba(0,0,0,0.15)`,
    "*":{
        transition: 'all ease-out 200ms',
    },
    'h6':{
        fontSize: open ? '24px' : '18px',
        padding: '5px',
        lineHeight: 1
    }

}));

export const CounterBlock = styled(Stack)(({ theme, open, background }) => ({
    width: '26%',
    height: '15vh',
    background,
    borderRadius: 'max(16px , 1.4vw)',
    padding: 'max(16px , 1.4vw)',
    boxShadow: theme.shadows[0],
}));

export const ChartContainer = styled(Box)(({ theme, left, height }) => ({
    position: 'relative',
    left: left || 'min(-30px, -3vw)',
    width: '100%',
    height: height || '48vh',
    '*':{
        outline: 'none',
    }
    // overflow: 'hidden',
}));