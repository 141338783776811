import styled from '@emotion/styled';
import imageSrc from '../assets/images/signin.png'; 
import theme from './CustomTheme'
import { Stack } from '@mui/system';

export const SocialLink = styled("a")(({ theme }) => ({
    width: '46px',
    aspectRatio: 1,
    'svg':{
        width: '100%',
        height: '100%'
    },
    // '&:hover': {
    //     transform: 'scale(1.1)'
    // }
}));

export const ReportBox = styled(Stack)(({theme}) => ({
    borderRadius: 'max(8px, 0.75vw)',
    background: theme.palette.gray[300],
    width: '100%',
    height: '85vh', 
    padding: 'max(16px, 1vw)',
    boxShadow: theme.shadows[0]
}));