import { Table, TableBody, TableRow } from '@mui/material';
import theme from './CustomTheme'
import styled from "@emotion/styled";

export const StyledTable = styled(Table)(({ theme }) => ({
    overflow: 'hidden',
    width: "100%",
    boxShadow: '0px 4px 8px -1px rgba(0 ,0 ,0 , 0.1), 0px 2px 8px 0px rgba(0 ,0 , 0 , 0.1), 0px 1px 8px 0px rgba(0 ,0, 0 , 0.1)',
    borderRadius: 'max(16px, 2vw)',
    marginBlock: '2vw 1vw',
    background: theme.palette.grey[50],
    maxHeight: '65vh',
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
    overflow: 'auto',
}));


export const StyledTableHeadRow = styled(TableRow)(({theme, background}) => ({
    background: background || theme.palette.primary.main,
    paddingBlock: 'max(18px, 1.2vw)',
    boxShadow: '0px 3px 8px 0px rgba(0 ,0 ,0 , 0.3)',
    
    '& .cell-item': {
        borderInlineEnd: `1px solid ${theme.palette.grey[100]}`,
        'h6':{
            color: theme.palette.grey[100],
        },
        'svg':{
            fill: theme.palette.grey[100],
        }
    }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    paddingBlock: 'max(16px, 0.7vw)',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    // columnGap: 'max(10px, 0.5vw)',
    '.MuiIconButton-root':{
        borderRadius: '10px',
        backgroundColor: theme.palette.gray[400],
        minWidth: 'unset',
        aspectRatio: '1',
        padding: '10px',
        lineHeight: '1',
        transition: 'all ease-out 150ms !important',
        color: theme.palette.gray[600],
        '&:hover': {
            backgroundColor: theme.palette.gray[400],
            color: theme.palette.secondary.main 
        },
    },
}));
