import {Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserAreaChart from '../../charts/UserAreaChart';
import UserPieChart from '../../charts/UserPieChart';
import moment from 'moment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import UserDetailsData from './UserDetailsData';
import CompletedConfigurationsData from './CompletedConfigurationsData';
import { ReportBox } from '../../../styles/CustomStyles';
import IncompletedConfigurationsData from './IncompletedConfigurationsData';

function FullReport({reportData, handleToggleModal}) {
    const [barChartConfigurations, setBarChartConfigurations] = useState([]);
	const [pieChartConfigurations, setPieChartConfigurations] = useState([]);


    const handleBuildChartsData = () => {
        const progressRecs = reportData.UserFunnelProgresses;
		//For Bar Chart
		const startDate = moment().subtract(11, 'months');
		const last12MonthsProgressRecs = progressRecs.filter((progressRec) => {
			const createdAt = moment(progressRec.createdAt);
			return createdAt.isSameOrAfter(startDate);
		});
		const barChartData = last12MonthsProgressRecs.reduce((accumulator, progressRec) => {
			const hasConfigurationResults = progressRec?.ConfigurationResults?.length > 0;
			const month = moment(progressRec.createdAt).format('MMM YYYY');

			if (!accumulator[month]) {
				accumulator[month] = {
					month,
					completedConfigurationsNumber: 0,
					incompletedConfigurationsNumber: 0,
				};
			}
		
			// Increment counts based on configuration
			if (hasConfigurationResults) {
				accumulator[month].completedConfigurationsNumber++;
			} else {
				accumulator[month].incompletedConfigurationsNumber++;
			}
		
			return accumulator;
		}, {});

		//for pie chart
		const completedConfigurations = progressRecs.filter(item => item.ConfigurationResults.length > 0).length;
		const incompleteConfigurations = progressRecs.length - completedConfigurations;

		// Calculate percentages
		const completedPercentage = ((completedConfigurations / progressRecs.length) * 100).toFixed(2);
		const incompletePercentage = ((incompleteConfigurations / progressRecs.length) * 100).toFixed(2);

		setPieChartConfigurations([
			{ 
				name: "Completed Configurations", 
				value: completedConfigurations, 
				percentage: completedPercentage 
			},
			{ 
				name: "Incompleted Configurations",
				value: incompleteConfigurations, 
				percentage: incompletePercentage 
			},
		])
        const sortedBarChartData = Object.entries(barChartData)
        .sort(([a], [b]) => moment(a, 'MMM YYYY').valueOf() - moment(b, 'MMM YYYY').valueOf())
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
		setBarChartConfigurations(Object.values(sortedBarChartData));
	};

    useEffect(() => {
        if(!reportData.UserFunnelProgresses.length) return;
       
        handleBuildChartsData();
	}, [reportData])

    return (
        <>
            <IconButton 
                className={'absolute'} 
                sx={{top: '2%', left: '1%'}} 
                size={'large'} 
                color={'primary'}
                onClick={() => handleToggleModal(null)}
            >
                <CloseRoundedIcon fontSize="inherit"/>
            </IconButton>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Box width={'30%'}>
                    <Box width={'100%'}>
                        <Typography variant={'h6'} component={'h3'} fontWeight={700} textAlign={'center'}>
                            Configuration Completion Overview
                        </Typography> 
                        {pieChartConfigurations.length > 0 && <UserPieChart data={pieChartConfigurations} height={'30vh'} left={'0'}/>}
                    </Box>
                    <Box width={'100%'}>
                        <Typography variant={'h6'} component={'h3'} fontWeight={700} textAlign={'center'}>
                            12-Month Historical Configuration Trend Chart
                        </Typography>
                        {barChartConfigurations.length > 0 && 
                            <UserAreaChart
                                data={barChartConfigurations}
                                xAxis={
                                    {
                                        key: 'month',
                                        name: 'Month'
                                    }
                                }
                                yAxis={[
                                    {
                                        key: 'completedConfigurationsNumber',
                                        name: "Completed Configurations"
                                    },
                                    {
                                        key: 'incompletedConfigurationsNumber',
                                        name: "Incompleted Configurations"
                                    }
                                ]}
                            />
                        }
                    </Box>	
                </Box>
                <Box width={'65%'} className={'scrollable'}>
                    <ReportBox>
                        <Box sx={{overflowY: 'auto', pb: 'max(16px, 1vw)', paddingInlineEnd: '10px'}}>
                            <UserDetailsData reportData={reportData}/>
                            <CompletedConfigurationsData reportData={reportData}/>
                            <IncompletedConfigurationsData reportData={reportData}/>
                        </Box>
                    </ReportBox>
                </Box>
            </Stack>
        </>
    )
}

export default FullReport