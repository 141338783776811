import { Box, Button, ButtonGroup, Collapse, FormControl, IconButton, InputLabel, List, ListItem, MenuItem, Modal, Select, Stack, TableCell, TableContainer, TableHead, TablePagination, TableSortLabel, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { StyledTable, StyledTableBody, StyledTableHeadRow, StyledTableRow } from '../../styles/TableStyles';
import { visuallyHidden } from '@mui/utils';
import theme from '../../styles/CustomTheme';
import PropTypes from 'prop-types';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useReportsContext } from '../../contexts/ReportsContext';
import { InitialsBox } from '../../styles/DashboardStyles';
import { getInitials, getNameById } from '../../helpers/Tools';
import { useSegmentsContext } from '../../contexts/SegmentsContext';
import { segmentItemSm } from '../../styles/SegmentsStyle';
import AppContext from '../../contexts/AppContext';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useFunnelsContext } from '../../contexts/FunnelsContext';
import Loader from '../Loader';
import { modalStyle } from '../../styles/ModalStyles';
import FullReport from './full_report/FullReport';
import { ExportToExcel } from '../ExportToExcel';

function EnhancedTableHead({order, orderBy, rowCount, onRequestSort, isDashboard}) {

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
        {
            id: 'user',
            numeric: false,
            label: 'User',
            disablePadding: true,
        },
        {
            id: 'region',
            numeric: false,       
            label: 'Region',
            disablePadding: true,
        },
        {
            id: 'segments',
            numeric: false,
            label: 'Segments',
            disablePadding: true,
        },
        {
            id: 'successful_configurations',
            numeric: true,
            label: 'Successful Configurations',
            disablePadding: true,
        },
        {
            id: 'configurations_in_progress',
            numeric: true,
            label: 'Configurations In Progress',
            disablePadding: true,
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: true,
            label: 'Actions',
        },
    ]

    return (
        <TableHead>
            <StyledTableHeadRow background={isDashboard ? '#5c73bb' :  null}>
            {headCells.map(headCell => (
                <TableCell
                    key={headCell.id}
                    align={'center'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    className={'cell-item'}
                    sx={{p: isDashboard ? '8px' : '16px'}}
                >
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <Typography 
                            variant='h6' 
                            component='h6' 
                            textAlign={'center'}
                            sx={{fontSize: isDashboard ? '16px' : '1.25rem'}}
                        > 
                            {headCell.label} 
                        </Typography>
                        {orderBy === headCell.id ? (
                        <Box component="span" sx={[visuallyHidden, {color: theme.palette.grey[50]}]}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
            ))}
            </StyledTableHeadRow>
        </TableHead>
    );
}
  
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function CustomRow({ rowData, color, handleToggleModal, isDashboard }) {
    const {segments} = useSegmentsContext();
    const {funnels} = useFunnelsContext();
    const {companies} = useContext(AppContext);
    const [open, setOpen] = useState(false);

    const completedConfigurations = rowData?.UserFunnelProgresses?.filter(item => item?.ConfigurationResults.length);
    const incompletedConfigurations = rowData?.UserFunnelProgresses?.filter(item => !item?.ConfigurationResults.length);
    
    // Function to separate configurations by funnelId
    const countConfigurationsByFunnel = (configurations) => {
        const counts = {};

        configurations.forEach(config => {
            const funnelName = funnels.find(funnel => +funnel.id === +config.funnelId)?.title;
            counts[funnelName] = (counts[funnelName] || 0) + 1;
        });

        return counts;
    }

    return (
        <>
            <StyledTableRow sx={{ '& > *': {borderBottom: 'unset'}}}>
                <TableCell width={'22%'} sx={{paddingBlock: isDashboard ? '10px' : '16px'}}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={'10px'}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                            sx={{borderRadius: '50% !important', padding: '0 !important'}}
                        >
                            {open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                        </IconButton>
                        <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
                            <InitialsBox open={true} justifyContent={'center'} alignItems={'center'} background={color}>
                                <Typography variant='h6' component='h6'>{getInitials(rowData.fullName)}</Typography>
                            </InitialsBox>
                            <Typography variant={'p_sm'} component={'p'} fontWeight={500}>{rowData.fullName}</Typography>
                        </Stack>
                        <Box></Box>
                    </Stack>
                </TableCell>
                <TableCell align={'center'}>
                    <Typography component={'span'} variant={'p'} fontWeight={500}>{rowData.region}</Typography>
                </TableCell>
                <TableCell>
                    <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'center'} gap={'10px'}>
                        {rowData.segments?.map(segId => (
                            <Typography component={'span'} variant={'p_sm'} sx={segmentItemSm}>
                                {getNameById(segId, 'name', segments)}
                            </Typography>
                        ))}
                    </Stack>
                </TableCell>
                <TableCell align={'center'} width={'15%'}>
                    <Typography component={'span'} variant={'p'} fontWeight={700}>
                        {completedConfigurations.length}
                    </Typography>
                </TableCell>
                <TableCell align={'center'} width={'15%'}>
                    <Typography component={'span'} variant={'p'} fontWeight={700}>
                        {incompletedConfigurations.length}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        size={'large'}
                        endIcon={<InfoRoundedIcon/>}
                        onClick={()=>handleToggleModal(rowData)}
                    >
                        full info
                    </Button>
                </TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit className={'scrollable'}>
                        <Stack 
                            direction={'row'} 
                            justifyContent={'space-between'} 
                            gap={'30px'}
                            sx={{ m: 1,
                                maxHeight: '208px',
                                overflowY: 'auto',
                                paddingInlineEnd: '10px'
                            }} 
                        >
                            <Box>
                                <Typography variant="h6" component="h6" sx={{textDecoration: 'underLine', fontWeight: 700}}>
                                    User Info: 
                                </Typography>
                                <List sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside'
                                }}>
                                    <ListItem sx={{ display: 'list-item' }}>
                                        <Typography component={'span'} variant={'p_sm'}>Company: </Typography>
                                        <Typography component={'span'} variant={'p_sm'} sx={{textDecoration: 'underline', fontWeight: 600}}>
                                            {rowData.companyId ? 
                                                getNameById(rowData.companyId, 'companyName', companies)
                                                :
                                                'OTHER'
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>
                                        <Typography component={'span'} variant={'p_sm'}>Country: </Typography>
                                        <Typography component={'span'} variant={'p_sm'} sx={{textDecoration: 'underline', fontWeight: 600}}>
                                            {rowData.country}
                                        </Typography> 
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>
                                        <Typography component={'span'} variant={'p_sm'}>Email: </Typography>
                                        <Typography component={'span'} variant={'p_sm'} sx={{textDecoration: 'underline', fontWeight: 600}}>
                                            {rowData.email}
                                        </Typography> 
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>
                                        <Typography component={'span'} variant={'p_sm'}>Phone: </Typography>
                                        <Typography component={'span'} variant={'p_sm'} sx={{textDecoration: 'underline', fontWeight: 600}}>
                                            {rowData.phone}
                                        </Typography> 
                                    </ListItem>
                                </List>
                            </Box>
                            <Box>
                                <Typography variant="h6" component="h6" sx={{textDecoration: 'underLine', fontWeight: 700}}>
                                    Completed Funnels: 
                                </Typography>
                                <Box py={'8px'}>
                                    {Object.entries(countConfigurationsByFunnel(completedConfigurations)).map(([funnelName, count]) => (
                                        <Stack direction={'row'} gap={'4px'} key={funnelName} py={'8px'}>
                                            <Typography component={'span'} variant={'p_sm'}>{funnelName}:</Typography>
                                            <Typography component={'span'} variant={'p_sm'} sx={{textDecoration: 'underline', fontWeight: 600}}> 
                                                {count} completed configuration{count !== 1 ? 's' : ''}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="h6" component="h6" sx={{textDecoration: 'underLine', fontWeight: 700}}>
                                    Incompleted Funnels: 
                                </Typography>
                                <Box py={'8px'}>
                                    {Object.entries(countConfigurationsByFunnel(incompletedConfigurations)).map(([funnelName, count]) => (
                                        <Stack direction={'row'} gap={'4px'} key={funnelName} py={'8px'}>
                                            <Typography component={'span'} variant={'p_sm'}>{funnelName}:</Typography>
                                            <Typography component={'span'} variant={'p_sm'} sx={{textDecoration: 'underline', fontWeight: 600}}> 
                                                {count} incompleted configuration{count !== 1 ? 's' : ''}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Box>
                            </Box>
                        </Stack>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </>
    );
}

CustomRow.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

function Reports({isDashboard}) {
    const {segments} = useSegmentsContext();
    const {reports, isLoading} = useReportsContext();
    const {companies} = useContext(AppContext);
    const [sortOrder, setSortOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('user');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterBy, setFilterBy]  = useState(''); //fullName, segment, region
    const [searchQuery, setSearchQuery] = useState('');
    const [targetReport, setTargetReport] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [excelData, setExcelData] = useState([]);

    const colors = [
        '#FFD1DC', // Pink
        '#FFE4B5', // Moccasin
        '#B0C4DE', // Light Steel Blue
        '#C1FFC1', // Pale Green
        '#FFDAB9', // Peach
        '#ADD8E6', // Light Blue
        '#FFECB3', // Light Goldenrod
        '#FFA07A', // Light Salmon
        '#E0BBE4', // Lavender Pink
        '#F0E68C', // Khaki
        '#FFECDB', // Blanched Almond
        '#FFEF96', // Pale Goldenrod
        '#98FB98', // Pale Green
        '#E6E6FA', // Lavender
        '#FFB6C1', // Light Pink
        '#B4EEB4', // Mint Green
        '#AFEEEE', // Pale Turquoise
        '#FFC0CB', // Pink
    ];

    const getRandomColor = (index) => {
        const normalizedIndex = index % colors.length;
        return colors[normalizedIndex];
    };

    const handleToggleModal = (report) => {
        setTargetReport(report || null);
        setModalOpen(!modalOpen);
    }

    const buildExcel = () => {
		const customizedExcelArr = stableSort(reports, getComparator(sortOrder, orderBy))
            .filter(report => {
                if(filterBy.length === 0 || (filterBy === 'Segment' && searchQuery?.length === 0)) return true;
                if (filterBy === 'Full Name') {
                    return report?.fullName?.toLowerCase().includes(searchQuery.toLowerCase());
                } else if (filterBy === 'Segment') {
                    return report?.segments.includes(searchQuery);
                } else if (filterBy === 'Region') {
                    return report?.region.toLowerCase().includes(searchQuery.toLowerCase());
                }
                return report;
            })
            .map((report, key) =>{
                const excel_data_obj = {
                    'Full Name': report.fullName,
                    'Email': report.email,
                    'Phone': report.phone,
                    'Company': report.companyId ? getNameById(report.companyId, 'companyName', companies) : 'OTHER',
                    'Region': report.region,
                    'Country': report.country,
                    'Segments': report?.segments?.map((segId, key) => (`${getNameById(segId, 'name', segments)}`)).join(', '),
                    'Successful Configurations': report?.UserFunnelProgresses?.filter(item => item?.ConfigurationResults.length).length,
                    'Configurations In Progress': report?.UserFunnelProgresses?.filter(item => !item?.ConfigurationResults?.length).length,
                };

                return excel_data_obj;
            });
        setExcelData(customizedExcelArr);
	};

    //mui table
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) return -1;
        if (b[orderBy] > a[orderBy]) return 1;
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    useEffect(() => {
        if(!reports.length) return;
		buildExcel();
	}, [reports, sortOrder, orderBy, filterBy, searchQuery]);
    
    return (
        <>
            <Box>
                <Stack
                    direction={'column'}
                    fullWidth
                >
                    {!isDashboard &&
                        <Stack 
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            fullWidth
                        >
                            <Stack direction={'row'} alignItems={'center'} gap={5}>
                                <Typography component={'h1'} variant={'h2'}> Reports </Typography>
                                <Stack direction={'row'} alignItems={'center'} gap={3}>
                                    <FormControl>
                                        <InputLabel id="reports-filtering-label">Search by</InputLabel>
                                        <Select
                                            value={filterBy}
                                            onChange={(e) => {setSearchQuery(''); setFilterBy(e.target.value)}}
                                            labelId="reports-filtering-label"
                                            label="Search by"
                                            sx={{minWidth: '220px'}}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            <MenuItem value="Segment">Segment</MenuItem>
                                            <MenuItem value="Region">Region</MenuItem>
                                            <MenuItem value="Full Name">Full Name</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {filterBy === 'Full Name' &&
                                        <TextField
                                            label={`Search by ${filterBy}`}
                                            color={'secondary'}
                                            autoComplete='ViewCrunch'
                                            sx={{minWidth: '300px'}}
                                            defaultValue={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    }
                                    {filterBy === 'Segment' &&
                                        <FormControl sx={{width: "max(200px, 20%)"}}>
                                            <InputLabel id="segment-select-label">Choose segment</InputLabel>
                                            <Select
                                                labelId="segment-select-label"
                                                id="segment-select"
                                                value={searchQuery}
                                                label="Choose company"
                                                onChange={(e)=>{setSearchQuery(e.target.value)}}
                                                sx={{minWidth: '220px'}}
                                            >
                                                {
                                                    segments?.length > 0 && segments?.map(segment=>(
                                                        <MenuItem value={segment?.id}>{segment?.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    }
                                    {filterBy === 'Region' &&
                                        <FormControl sx={{width: "max(200px, 20%)"}}>
                                            <InputLabel id="segment-select-label">Choose Region</InputLabel>
                                            <Select
                                                labelId="region-select-label"
                                                id="segment-select"
                                                value={searchQuery}
                                                label="Choose company"
                                                onChange={(e)=>{setSearchQuery(e.target.value)}}
                                                sx={{minWidth: '220px'}}
                                            >
                                                <MenuItem value={"Asia"}>{"Asia"}</MenuItem>
                                                <MenuItem value={"Africa"}>{"Africa"}</MenuItem>
                                                <MenuItem value={"Oceania"}>{"Australia and Oceania"}</MenuItem>
                                                <MenuItem value={"Europe"}>{"Europe"}</MenuItem>
                                                <MenuItem value={"North America"}>{"North America"}</MenuItem>
                                                <MenuItem value={"South America"}>{"South America"}</MenuItem>
                                                <MenuItem value={"Antarctica"}>{"Antarctica"}</MenuItem> 
                                            </Select>
                                        </FormControl>
                                    }
                                </Stack>
                            </Stack>
                            <ExportToExcel apiData={excelData} fileName={'reports'}/>
                        </Stack>
                    }
                </Stack>
                {
                    reports.length > 0 && !isLoading && 
                    <>
                        {!isDashboard &&
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'end'} mt={3}>
                                <TablePagination
                                    labelRowsPerPage={'per page'}
                                    labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { 
                                        return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`; 
                                    }}
                                    rowsPerPageOptions={[5, 10, 15]}
                                    component="div"
                                    count={reports.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Stack>
                        }
                        <TableContainer>
                            <StyledTable aria-label="customized table" sx={{mt: '8px !important'}}>
                                <EnhancedTableHead
                                    rowCount={reports.length}
                                    order={sortOrder}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    isDashboard={isDashboard}
                                />
                                <StyledTableBody>  
                                    {
                                        stableSort(reports, getComparator(sortOrder, orderBy))
                                        .slice(
                                            isDashboard ? 0 : page * rowsPerPage,
                                            isDashboard ? 2 : page * rowsPerPage + rowsPerPage
                                        )
                                        .filter(report => {
                                            if(filterBy.length === 0 || (filterBy === 'Segment' && searchQuery?.length === 0)) return true;
                                            if (filterBy === 'Full Name') {
                                                return report?.fullName?.toLowerCase().includes(searchQuery?.toLowerCase());
                                            } else if (filterBy === 'Segment') {
                                                return report?.segments?.includes(searchQuery);
                                            } else if (filterBy === 'Region') {
                                                return report?.region?.toLowerCase()?.includes(searchQuery?.toLowerCase());
                                            }
                                            return report;
                                        })
                                        .map((report, key) =>(
                                            <CustomRow 
                                                key={report.id} 
                                                rowData={report} 
                                                color={getRandomColor(key)}
                                                handleToggleModal={handleToggleModal}
                                                isDashboard={isDashboard}
                                            />
                                        ))
                                    }
                                </StyledTableBody>
                            </StyledTable>
                        </TableContainer>
                    </>
                }
                {reports.length === 0 && !isLoading && 
                    <Stack width={'100%'} height={'70vh'} justifyContent={'center'} alignItems={'center'}>
                        <Typography variant={'h3'} component={'h2'}>No reports found</Typography>
                    </Stack>
                }
                {reports.length === 0 && isLoading && <Loader/>}
            </Box>
            <Modal
				open={modalOpen}
				onClose={()=>handleToggleModal(null)}
			>
				<Box sx={{...modalStyle, width: '90vw', maxHeight: '95vh'}}>
                    <FullReport 
                        reportData={targetReport} 
                        handleToggleModal={handleToggleModal}
                    />
				</Box>
			</Modal>
        </>
    )
}

export default Reports