import React, { useCallback, useState } from 'react'
import { ChartContainer } from '../../styles/DashboardStyles'
import { Legend, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import CustomChartTooltip from './CustomChartTooltip';
import CustomChartLegend from './CustomChartLegend';
import { Typography } from '@mui/material';
import theme from '../../styles/CustomTheme';

function UserPieChart({data, height, left}) {
    const colors = ['#5c73bb', theme.palette.secondary.dark, '#f6b89e', '#fc7ec7'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <Typography fontWeight={700} fontSize={'max(16px, 1vw)'} component="text" x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`} 
            </Typography>
        );
    };

    return (
        <ChartContainer height={height} left={left}>
            <ResponsiveContainer width="100%" height={'100%'}>
                <PieChart width={'100%'} height={'100%'} >
                    <Pie
                        dataKey="value"
                        data={data}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        cx="50%"
                        cy="50%"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomChartTooltip />} />
                    <Legend content={<CustomChartLegend direction={'column'}/>} layout="vertical" align="right" verticalAlign="middle" />
                </PieChart>
            </ResponsiveContainer>
        </ChartContainer>
    )
}

export default UserPieChart