import React from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  BarChart,
  Bar,
  Legend,
  Cell
} from 'recharts';
import CustomChartTooltip from './CustomChartTooltip';
import theme from '../../styles/CustomTheme';
import { ChartContainer } from '../../styles/DashboardStyles';
import CustomChartLegend from './CustomChartLegend';
import CustomTick from './CustomTick';
import CustomBarChartLegend from './CustomBarChartLegend';

function UserBarChart({ data, xAxis, yAxis, height }) {
    return (
        <ChartContainer left={'0'} height={height}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart width={'100%'} height={'100%'} data={data}>
                    <defs>
                        {data.map((barData, index) => (
                            <linearGradient id={`gradient-${index}`} x1="0" y1="0" x2="0" y2="1" key={barData.color}>
                                <stop offset="0%" stopColor={barData.color} stopOpacity={1}></stop>
                                <stop offset="75%" stopColor={barData.color} stopOpacity={0.5}></stop>
                            </linearGradient>
                        ))}
                    </defs>
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey={xAxis.key} interval={0} display={'none'}/>
                    <YAxis tick={<CustomTick rotate={'0deg'} />} />
                    <Tooltip content={<CustomChartTooltip/>} />
                    <Bar
                        dataKey={yAxis[0].key}
                        name={yAxis[0].name}
                        stackId="1"
                    >
                        {data.map((barData, index) => (
                            <Cell key={index} fill={`url(#gradient-${index})`} />
                        ))}
                    </Bar>
                    <Legend content={<CustomBarChartLegend direction={'row'} data={data}/>} />
                </BarChart>
            </ResponsiveContainer>
        </ChartContainer>
    );
}

export default UserBarChart