import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import AppContext from './AppContext';
import customTranslations from '../data/staticTranslations.json'
import { getUserProgressRecs } from '../api/progressApi';
import { handlingAfterFetch } from '../helpers/Tools';
import { useUIContext } from './UIContext';

const CustomerContext = createContext();

export const useCustomerContext = () => useContext(CustomerContext);

export const CustomerProvider = ({ children }) => {
    const {setAlertObject} = useUIContext();
    const {loggedInUser, languages} = useContext(AppContext);
    const [customerLanguage, setCustomerLanguage] = useState({});
    const [progressRecs, setProgressRecs] = useState([]);


    const systemMessages = customTranslations.items.filter(item=> item.location === 'system_message').reduce((result, item) => {
        const { item: itemName, name_en, name_es } = item;
        result[itemName] = { name_en, name_es };
        return result;
    }, {});

    const handleGetUserProgressRecs = async() => {
        await handlingAfterFetch( 
            getUserProgressRecs(), 
            (res) => {
                if (res.success && res.data) setProgressRecs(res.data)
				
            },
            setAlertObject,
        );
    }

    useEffect(() => {
        setCustomerLanguage(languages.find(language=> +language.id === +loggedInUser.languageId));
        handleGetUserProgressRecs();
    }, [loggedInUser])

    return (
        <CustomerContext.Provider value={{ customerLanguage, setCustomerLanguage, systemMessages, progressRecs, setProgressRecs}}>
            {children}
        </CustomerContext.Provider>
    );
};