import React, { createContext, useContext, useEffect, useState } from 'react';
import { handlingAfterFetch } from '../helpers/Tools';
import { ADMIN_PERMISSIONS } from '../helpers';
import { useUIContext } from './UIContext';
import moment from 'moment';
import AppContext from './AppContext';
import { getReports, getReportsByParams } from '../api/reportsApi';

const ReportsContext = createContext();

export const useReportsContext = () => useContext(ReportsContext);

export const ReportsProvider = ({ children }) => {
    const {setAlertObject} = useUIContext();
    const {loggedInUser} = useContext(AppContext);
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
 
    const handleGetReports = async() => {
        const func = loggedInUser.permissions === ADMIN_PERMISSIONS ? getReports : getReportsByParams;

        const params = loggedInUser.permissions === ADMIN_PERMISSIONS ?  null : {
            segment: loggedInUser?.ManagerAssignment?.segmentId,
            countries: loggedInUser?.ManagerAssignment?.countries,
            region: loggedInUser?.ManagerAssignment?.region,
        };
        await handlingAfterFetch( 
            func(params), 
            (res) => {
                if(res?.success && res?.data) {
                    setReports(res.data);
                }
            },
            setAlertObject,
        );
        setIsLoading(false);
    }


    useEffect(() => {
        handleGetReports();
    }, [])
    
    const contextData = {
        reports, setReports, isLoading
    }

    return (
        <ReportsContext.Provider value={contextData}>
            {children}
        </ReportsContext.Provider>
    );
};