import React, { createContext, useContext, useState } from 'react';
import { HIDDEN_ALERT_OBJECT } from '../helpers/AlertPopup';
import { HIDDEN_DIALOG_OBJECT } from '../helpers/DialogPopup';

const UIContext = createContext();

export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
    const [alertObject, setAlertObject] = useState(HIDDEN_ALERT_OBJECT);
    const [dialogObject, setDialogObject] = useState(HIDDEN_DIALOG_OBJECT);

    return (
        <UIContext.Provider value={{ alertObject, setAlertObject, dialogObject, setDialogObject}}>
            {children}
        </UIContext.Provider>
    );
};