import React, { useEffect, useState, useContext } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { getNameById } from '../../../helpers/Tools';
import AppContext from '../../../contexts/AppContext';
import theme from '../../../styles/CustomTheme';
import { useFunnelsContext } from '../../../contexts/FunnelsContext';
import { useValvesContext } from '../../../contexts/ValvesContext';
import { UnitConversions } from '../../../helpers/UnitConversions';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import moment from 'moment';
import { SERVER_ENDPOINT } from '../../../helpers';

function IncompletedConfigurationsData({reportData}) {
    const {companies, languages} = useContext(AppContext);
    const {funnels} = useFunnelsContext();
    const {units, attributes, valves} = useValvesContext();
    const [configurations, setConfigurations] = useState([]);

    const separateConfigurationsByFunnel = (configurations) => {
        const separatedData = {};
        configurations.forEach(config => {
            const funnelName = funnels.find(funnel=> +funnel.id === +config.funnelId)?.title;
            
            if (!separatedData[funnelName]) separatedData[funnelName] = [];

            separatedData[funnelName].push(config);
        });

        return separatedData;
    }

    const getFunnelStepsData = (item) => {
        const funnel = funnels?.find(fnl => +fnl.id === +item?.funnelId);
        if (!funnel) return '';

        return(
            <>
                {
                    funnel?.FunnelSteps?.map((step, key) => (  
                        <Box my={1}>
                            <Typography component={'p'} variant={'p'} fontWeight={700} mb={1}>
                                Step {step?.stepIndex + 1} ({step?.title}) 
                                {+step.id === +item.activeStepId && <span style={{color: theme.palette.success.dark}}> CURRENT STEP</span>}
                            </Typography>
                            <Stack gap={'10px'} sx={{paddingInlineStart: '16px'}}>
                                {step?.FunnelStepQuestions?.map((question, key2) => (
                                    <Typography component={'p'} variant={'p_sm'}>
                                        {`${getNameById(question?.productAttributeId, 'attributeName', attributes)}: `}
                                        {item.ProgressQAs.map((qa => {
                                            if(+qa.stepId !== +step.id || +qa.questionId !== question.id) return;
                                            
                                            if(qa?.answerIds.length){
                                                let answerData = '';
                                                qa?.answerIds.map((answerId, key3) => {
                                                    const foundAnswer = question.FunnelStepsQuestionAnswers.find(answer=> +answer.id === +answerId);
                                                    if(!foundAnswer) return;
                                                    const foundAttr = attributes.find(attr => +attr.id === +question?.productAttributeId);
                                                    const foundValue = foundAttr?.AttributeValues.find(value => +value.id === +foundAnswer?.answerAttributeValueId);
                                                    if(foundValue.value) answerData += foundValue.value;
                                                    if(foundValue.valueUnit) {
                                                        const foundUnit = units.find(unit => +unit.id === +foundValue.valueUnit);
                                                        answerData += foundUnit.unitName;
                                                        
                                                        //convertions for other units
                                                        const notClearedKey = foundUnit.unitGroup.split(' ');
                                                        const key = notClearedKey[0].toLowerCase() + notClearedKey.slice(1, -1).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + notClearedKey.slice(-1)[0].charAt(0).toUpperCase() + notClearedKey.slice(-1)[0].slice(1, -1) + "Id";
                                                        const userPrefferedUnitId = item[`${key}`];
                                                        const userUnit = units.find(unit => +unit.id === +userPrefferedUnitId);
                                                        if(+userPrefferedUnitId !== foundValue.valueUnit){
                                                            const convertedValue = Math.round(UnitConversions[`${foundUnit.unitCode}TO${userUnit.unitCode}`](foundValue.value));
                                                            answerData += ` (${convertedValue} ${userUnit.unitName})`
                                                        }
                                                        
                                                    }
                                                    if(key3 !== qa?.answerIds.length -1) answerData += `, `;
                                                })
                                                return <u style={{fontWeight: 500}}>{answerData}</u>;
                                            } else if(qa?.answerValue?.length){
                                                const foundAttr = attributes.find(attr => +attr.id === +question?.productAttributeId);
                                                //if has value, means that it is ranged asttr, always have just 1 value
                                                const unitId = foundAttr?.AttributeValues[0].valueUnit;
                                                if(!unitId) return <u style={{fontWeight: 500}}>{`${qa?.answerValue}`}</u>
                                                const foundUnit = units.find(unit => +unit.id === +unitId);
                                                //convertions for other units
                                                const notClearedKey = foundUnit.unitGroup.split(' ');
                                                const key = notClearedKey[0].toLowerCase() + notClearedKey.slice(1, -1).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + notClearedKey.slice(-1)[0].charAt(0).toUpperCase() + notClearedKey.slice(-1)[0].slice(1, -1) + "Id";
                                                const userPrefferedUnitId = item[`${key}`];
                                                const userUnit = units.find(unit => +unit.id === +userPrefferedUnitId);
                                                if(+userPrefferedUnitId !== unitId){
                                                    //when its 'answerValue' and not 'answerIds' it saves value that user entered(in unit that user entered), so it needs to be converted back to default
                                                    const convertedBackValue = Math.round(UnitConversions[`${userUnit.unitCode}TO${foundUnit.unitCode}`](qa?.answerValue));
                                                    if(!convertedBackValue) return;
                                                    return <u style={{fontWeight: 500}}>{`${convertedBackValue} ${foundUnit.unitName} (${Math.round(qa?.answerValue)} ${userUnit.unitName})`}</u>
                                                } else {
                                                    return <u style={{fontWeight: 500}}>{`${qa?.answerValue} ${foundUnit.unitName}`}</u>
                                                }

                                            } 
                                            return null;
                                        }))}                          
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    ))
                }
            </>
        )
    }

    useEffect(() => {
        setConfigurations(
            reportData?.UserFunnelProgresses?.filter(item => !item?.ConfigurationResults?.length)
            .sort((a, b) => {
                const dateA = moment(a.updatedAt);
                const dateB = moment(b.updatedAt);
    
                return dateB.diff(dateA);
            })
        );
    }, [reportData])

    return (
        <>
            <Typography component={'h2'} variant={'h4'} fontWeight={800} mt={5} mb={3}>
                Incompleted Configurations: 
            </Typography>
            <Stack gap={'10px'} sx={{paddingInlineStart: '16px'}}>
                {Object.entries(separateConfigurationsByFunnel(configurations)).map(([funnelName, arr]) => (
                    <Box key={funnelName}>
                        <Typography component={'h6'} variant={'h6'}>{funnelName}:</Typography>
                        <Stack gap={'10px'} sx={{overflowY: 'auto', paddingInlineEnd: '10px'}}>
                            {arr.map((item, index)=>(
                                <Accordion key={index} sx={{background: theme.palette.gray[500]}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreRoundedIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                        sx={{background: theme.palette.gray[500]}}
                                        
                                    >
                                        <Typography component={'p'} variant={'p'}>
                                            Project Name: <span style={{fontWeight: 500}}>{item?.projectName}</span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack gap={'10px'}>
                                            <Box>
                                                <Typography component={'p'} variant={'p'} fontWeight={700} mb={1}>
                                                    Base Step
                                                </Typography>
                                                <Stack gap={'10px'} sx={{paddingInlineStart: '16px'}}>
                                                    <Typography component={'p'} variant={'p_sm'}>
                                                        Project Name: <u style={{fontWeight: 500}}>{item?.projectName}</u>
                                                    </Typography>
                                                    <Typography component={'p'} variant={'p_sm'}>
                                                        Units System: <u style={{fontWeight: 500}}>{item?.unitsSystem}</u>
                                                    </Typography>
                                                    <Typography component={'p'} variant={'p_sm'}>
                                                        Pressure Unit: <u style={{fontWeight: 500}}>
                                                            {getNameById(item?.pressureUnitId, 'unitName', units)}
                                                        </u>
                                                    </Typography>
                                                    <Typography component={'p'} variant={'p_sm'}>
                                                        Volume Unit: <u style={{fontWeight: 500}}>
                                                            {getNameById(item?.volumeUnitId, 'unitName', units)}
                                                        </u>
                                                    </Typography>
                                                    <Typography component={'p'} variant={'p_sm'}>
                                                        Flow Unit: <u style={{fontWeight: 500}}>
                                                            {getNameById(item?.flowUnitId, 'unitName', units)}
                                                        </u>
                                                    </Typography>
                                                    <Typography component={'p'} variant={'p_sm'}>
                                                        End Connection: <u style={{fontWeight: 500}}>
                                                            {getNameById(item?.endConnectionId, 'unitName', units)}
                                                        </u>
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                            {getFunnelStepsData(item)}
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </>
    )
}

export default IncompletedConfigurationsData