import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const getFunnels = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/funnels/getFunnels`,
	);
};

export const getFunnelByParams = async ({segmentId, valveTypeId}) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/funnels/getFunnelByParams?segment=${segmentId}&valveFunction=${valveTypeId}`
	);
};

export const createFunnel = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/funnels/createFunnel`,
        data
    );
};

export const editFunnel = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/funnels/updateFunnel`,
        data
    );
};

export const deleteFunnel = async (id) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/funnels/deleteFunnel`,
        {id}
    );
};