import React, { useContext } from 'react'
import AppContext from '../contexts/AppContext';
import { FormControl, Select, InputLabel, MenuItem, Stack } from '@mui/material';

function LanguagesSelector({languageId, setLanguageId, className, sx, size, label}) {
    const {languages} = useContext(AppContext);

    return (
        <FormControl className={className} sx={sx}>
            <InputLabel id="languages-select-label">{label || "Translation Language"}</InputLabel>
            <Select
                labelId="languages-select-label"
                id="languages-select"
                value={languageId}
                label={label || "Translation Language"}
                onChange={(e) => setLanguageId(e.target.value)}
                sx={{minWidth: '200px'}}
                size={size || 'small'}
            >
                {languages.map(lang=>(
                    <MenuItem value={lang.id}>
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <img 
                            width={20}
                            src={require(`../assets/images/flags/${lang.languageCode}.png`)}
                            alt={lang.languageCode} />
                            <span>{lang.languageName}</span>
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default LanguagesSelector