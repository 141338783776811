import React, { useContext } from 'react'
import { useNavigate } from 'react-router';
import { useUIContext } from '../contexts/UIContext';
import { userLogout } from '../api/usersApi';
import { Button, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ListItemStyles } from '../styles/DashboardStyles';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { handlingAfterFetch } from '../helpers/Tools';
import AppContext from '../contexts/AppContext';
import {SUCCESS_ALERT_OBJECT } from '../helpers/AlertPopup';
import customTranslations from '../data/staticTranslations.json';
import { useCustomerContext } from '../contexts/CustomerContext';

function Logout({isNavOpen, isDashboard}) {
    const {setAlertObject, setDialogObject} = useUIContext();
    const {loggedInUser, setLoggedInUser, languages} = useContext(AppContext);
    const {customerLanguage} = useCustomerContext() || {customerLanguage: {languageCode: 'en'}};

    const logoutBtnText = customTranslations.items.find(item=> item.location === 'sidebar' && item.item === 'logout')[`name_${customerLanguage.languageCode}`];
    const logoutTranslationItems = customTranslations.items.filter(item=> item.location === 'logout_dialog').reduce((result, item) => {
        const { item: itemName, name_en, name_es } = item;
        result[itemName] = { name_en, name_es };
        return result;
    }, {});

    const navigate = useNavigate();

    const doLogout = async() => {
        await handlingAfterFetch( 
            userLogout(), 
            (res) => {
                if(res?.success) {
                    setAlertObject({
						...SUCCESS_ALERT_OBJECT,
						message: 'You are successfully logged out!'
					});
                    setLoggedInUser({});
                    navigate('/auth/sign-in', { state: { subroute: '' } });           
                }
            },
            setAlertObject,
        );
    }

    const handleLogout = () => {
        const logoutConfig = {
            open: true, 
            ok: logoutTranslationItems.logout_proceed_btn[`name_${customerLanguage.languageCode}`], 
            cancel: logoutTranslationItems.logout_cancel_btn[`name_${customerLanguage.languageCode}`], 
            title: logoutTranslationItems.title[`name_${customerLanguage.languageCode}`], 
            description: logoutTranslationItems.sub_title[`name_${customerLanguage.languageCode}`],
            content: null,
            call_on_submit: () => doLogout()
        };

        setDialogObject(logoutConfig);
    }

    return (
        <>
            {
                isDashboard ?
                <Button 
                    variant={'contained'} 
                    color={'secondary'} 
                    endIcon={<LogoutRoundedIcon />} 
                    onClick={() => handleLogout()}
                    size={'large'}
                    sx={{fontWeight: 400}}
                >
                    {logoutBtnText}
                </Button>
                :
                <ListItem disablePadding sx={{mt: 'auto'}}>
                    <ListItemButton
                        sx={ListItemStyles}
                        isNavOpen={isNavOpen}
                        onClick={() => handleLogout()}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: isNavOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <LogoutRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={logoutBtnText} sx={{ opacity: isNavOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>  
            }
        </>
    )
}

export default Logout