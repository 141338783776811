import { Box, Stack, Typography } from '@mui/material';
import React from 'react'
import { Rectangle } from "recharts";
import theme from '../../styles/CustomTheme';


function CustomChartLegend({payload, options, direction}) {
    const getRectStyle = (background) => {
        return {
            borderRadius: '50%',
            width: '20px',
            aspectRatio: 1,
            background,
            boxShadow: theme.shadows[0]
        }
    }
    return (
        <Stack flexDirection={direction} justifyContent="center" alignItems="center" columnGap={'20px'} rowGap={'14px'} mt={direction === 'row' ? '10px' : '0'}>
            {payload.map((entry, index) => (
                <Stack key={`item-${index}`} flexDirection="row" alignItems="center" gap={'10px'}>
                    <span style={getRectStyle(entry.color)}></span>
                    <Typography variant="body2">
                        {entry.value}
                    </Typography>
                </Stack>
            ))}
      </Stack>
    )
}

export default CustomChartLegend