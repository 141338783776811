import React from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  AreaChart,
  Area,
  Legend,
} from 'recharts';
import CustomChartTooltip from './CustomChartTooltip';
import theme from '../../styles/CustomTheme';
import { ChartContainer } from '../../styles/DashboardStyles';
import CustomChartLegend from './CustomChartLegend';
import CustomTick from './CustomTick';

function UserAreaChart({ data, xAxis, yAxis, height }) {
    return (
        <ChartContainer left={'0'} height={height}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={'100%'} height={'100%'} data={data}>
                <defs>
                    <linearGradient id="completedGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={theme.palette.secondary.dark} stopOpacity={0.8}></stop>
                    <stop offset="75%" stopColor={theme.palette.secondary.dark} stopOpacity={0.1}></stop>
                    </linearGradient>
                    <linearGradient id="incompletedGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={theme.palette.primary.light} stopOpacity={0.8}></stop>
                    <stop offset="75%" stopColor={theme.palette.primary.light} stopOpacity={0.1}></stop>
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxis.key} tick={<CustomTick />} />
                <YAxis tick={<CustomTick />} />
                <Tooltip content={<CustomChartTooltip />} />
                <Legend content={<CustomChartLegend direction={'row'} />} />
                <Area
                    type="monotone"
                    dataKey={yAxis[0].key}
                    name={yAxis[0].name}
                    stackId="1"
                    fill="url(#completedGradient)"
                    stroke={theme.palette.secondary.dark}
                />
                <Area
                    type="monotone"
                    dataKey={yAxis[1].key}
                    name={yAxis[1].name}
                    stackId="1"
                    fill="url(#incompletedGradient)"
                    stroke={theme.palette.primary.light}
                />
                </AreaChart>
            </ResponsiveContainer>
        </ChartContainer>
    );
}

export default UserAreaChart;
