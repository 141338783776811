import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import AppContext from '../contexts/AppContext'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { Drawer, DrawerHeader, InitialsBox, ListItemStyles, dashboardContainer } from '../styles/DashboardStyles';
import dorotLogoWhite from '../assets/images/logoDOROT_white.png';
import theme from '../styles/CustomTheme';
import Logout from '../components/Logout';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { capitalizeString, getNameById } from '../helpers/Tools';
import Support from '../components/user_panel/Support';
import ManagerDashboard from '../components/manager_panel/ManagerDashboard';
import { FunnelsProvider } from '../contexts/FunnelsContext';
import { ReportsProvider } from '../contexts/ReportsContext';
import Reports from '../components/admin_panel/Reports';
import { CustomerProvider } from '../contexts/CustomerContext';
import MyAccount from '../components/user_panel/MyAccount';

function ManagerDashboardScreen() {
    const { screen } = useParams();
    const navigate = useNavigate();
    const {loggedInUser} = useContext(AppContext);
    const findingActiveScreen = screen || '';
	
	const [isNavOpen, setIsNavOpen] = useState(true);
    const [activeScreen, setActiveScreen] = useState(findingActiveScreen);

    const navItems = [
        {
            title: 'Dashboard',
            icon: <HomeRoundedIcon/>,
            id: ''
        },
        {
            title: 'Reports',
            icon: <FlagRoundedIcon/>,
            id: 'reports'
        },
        {
            title: 'My Account',
            icon: <ManageAccountsRoundedIcon/>,
            id: 'my-account'
        },  
        {
            title: 'Support',
            icon: <ContactSupportRoundedIcon/>,
            id: 'support'
        },
    ];

	const handleToggleDrawer = () => {
		setIsNavOpen(!isNavOpen);
	};

	const handleSetActiveScreen = (parentItem) => {
        setActiveScreen(parentItem === 'dashboard' ? '' : parentItem);
    }

	const getInitials = (fullName) => {
		const initials = fullName
		  .split(/\s+/)
		  .map(word => word.charAt(0).toUpperCase())
		  .join('');
	  
		return initials;
	}

	useEffect(() => {
        navigate(`/manager-dashboard/${activeScreen}`);
    }, [activeScreen, navigate, screen]);

    return (
        <Box>
			<Drawer variant="permanent" open={isNavOpen}>
				<DrawerHeader>
					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={5}>
						<Box>
							<img src={dorotLogoWhite} alt="dorot logo"/>
						</Box>
						<IconButton onClick={handleToggleDrawer}>
							{isNavOpen ? <ArrowBackIosNewRoundedIcon sx={{color: theme.palette.gray[200]}}/> : <ArrowForwardIosRoundedIcon sx={{color: theme.palette.gray[200]}}/>}
						</IconButton>
					</Stack>
				</DrawerHeader>
				<Divider sx={{background: theme.palette.gray[50]}}/>
				<Stack direction={'row'} alignItems={'center'} gap={2} px={2.5} py={1.5}>
					<InitialsBox open={isNavOpen} justifyContent={'center'} alignItems={'center'}>
						<Typography variant='h6' component='h6'>{getInitials(loggedInUser.fullName)}</Typography>
					</InitialsBox>
					<Box>
						<Typography variant='p_sm' component='p' color={theme.palette.gray[200]}>{capitalizeString(loggedInUser.fullName)}</Typography>
					</Box>
				</Stack>
				<Divider sx={{background: theme.palette.gray[50]}}/>
				<List>
                    {navItems.map(item => (
                        <Box key={item.id}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    sx={() => ListItemStyles(theme, activeScreen === item.id ? true : false)}
                                    onClick={()=>{
                                        handleSetActiveScreen(item.id);
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: isNavOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} sx={{ opacity: isNavOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </Box>
                    ))}
				</List>
				<Divider sx={{background: theme.palette.gray[50]}}/>
				<List sx={{display: 'flex', flexDirection: 'column', height: 'inherit'}}>
                    <Logout isNavOpen={isNavOpen}/>
				</List>
			</Drawer>
            <Box sx={() => dashboardContainer(theme, isNavOpen)}>
                {activeScreen === '' && <ManagerDashboard/>}
                {activeScreen === 'reports' && <Reports/>}
                {(activeScreen === 'my-account' || activeScreen === 'support') && 
                    <CustomerProvider>
                        {   activeScreen === 'support' ?
                            <Support screen={activeScreen}/> 
                            :
                            <MyAccount screen={activeScreen}/>
                        }
                    </CustomerProvider>
                }
                
            </Box>
		</Box>
    )
}

export default ManagerDashboardScreen