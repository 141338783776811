import { Stack } from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {ReactComponent as DorotIcon} from '../assets/images/loader.svg'; 

const useStyles = makeStyles({
  loader: {
    width: '100%',
    height: '100%',
    minHeight: 'inherit',
    '& svg' : {
      maxWidth: '80px',
    },
    '& .svg-elem-1' : {
      [`-webkit-animation`] : `animate-svg-fill-1 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s both infinite alternate`,
      animation : `animate-svg-fill-1 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s both infinite alternate`
    },
    '& .svg-elem-2' : {
      [`-webkit-animation`] : `animate-svg-fill-2 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s both infinite alternate`,
      animation: `animate-svg-fill-2 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s both  infinite alternate`
    },   
    '& .svg-elem-3' : {
      [`-webkit-animation`] :` animate-svg-fill-3 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 1s both infinite alternate`,
      animation: `animate-svg-fill-3 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.8s both infinite alternate`
    }
  },
});

function Loader() {
  const classes = useStyles();

  return (
    <Stack justifyContent={'center'} alignItems={'center'} className={classes.loader}>
        <DorotIcon/>
    </Stack>
  )
}

export default Loader