import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const VerifyUserEmail = async (user_data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/VerifyEmail`,
		user_data,
	);
};

export const ChangeEmailVerify = async () => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/changeEmailVerify`,
	);
};

export const ChangeNewEmailVerify = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/changeNewEmailVerify`,
		data
	);
}

export const ChangeUserEmail = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/users/changeUserEmail`,
		data
	);
}


export const fetchVerifiedUser = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/users/userVerify`,
	);
};

export const userSignIn = async (user_data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/signIn`,
		user_data,
	);
};

export const userPreSignUp = async (user_data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/preSignUp`,
		user_data,
	);
};

export const userSignUp = async (user_data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/signUp`,
		user_data,
	);
};

export const GetEmailRemainingTime = async () => {
	return await fetchGet(`${SERVER_ENDPOINT}/api/users/getRemainingTime`);
};

export const getAllUsers = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/users/getUsers`,
	);
};

export const createUser = async (user_data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/createUser`,
		user_data,
	);
};

export const editUser = async (user_data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/users/updateUser`,
		user_data,
	);
};

export const deleteUser = async (id) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/users/deleteUser`,
		id
	);
};

export const userLogout = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/users/userLogout`,
	);
};
