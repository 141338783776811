import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const getLanguages = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/languages/getLanguages`,
    );
};

