import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAllSegments } from '../api/segmentsApi';
import { GetAllUnits, getValveTypes, getValves, getValvesAttributes } from '../api/valvesApi';
import { handlingAfterFetch } from '../helpers/Tools';
import { USER_PERMISSIONS } from '../helpers';
import { useUIContext } from './UIContext';
import moment from 'moment';
import AppContext from './AppContext';

const ValvesContext = createContext();

export const useValvesContext = () => useContext(ValvesContext);

export const ValvesProvider = ({ children }) => {
    const {setAlertObject} = useUIContext();
    const { loggedInUser } = useContext(AppContext);
    const [attributes, setAttributes] = useState([]);
    const [valves, setValves] = useState([]);
    const [valveTypes, setValveTypes] = useState([]);
    const [units, setUnits] = useState([]);

    const handleGetValves = async() => {
		await handlingAfterFetch( 
            getValves(), 
            (res) => {
                if (res.success && res.data) {
                    setValves([...res.data]);
                }
            },
            setAlertObject,
        );
	}

    const handleGetValvesAttributes = async () => {
        await handlingAfterFetch( 
            getValvesAttributes(), 
            (res) => {
                if (res.success && res.data) {
                    const sortedAttributes = res.data.map(item => {
                        return { ...item, segments: JSON.parse(item.segments)};
                    }).sort((a, b) => {
                        // Compare the "createdAt" values in descending order
                        return moment(b.createdAt).diff(moment(a.createdAt));
                    });
                    setAttributes(sortedAttributes);
                }
            },
            setAlertObject,
        );
    }

    const handleGetValveTypes = async () => {
        await handlingAfterFetch( 
            getValveTypes(), 
            (res) => {
                if (res.success && res.data) {
                    const sortedValveTypes = res.data.sort((a, b) => {
						const createdAtA = moment(a.createdAt);
						const createdAtB = moment(b.createdAt);
						return createdAtB - createdAtA;
					});
					
					setValveTypes(sortedValveTypes);
                }
            },
            setAlertObject,
        );
    }

    const handleGetUnits = async () => {
		await handlingAfterFetch(
			GetAllUnits(),
			(res) => {
				if (res.success && res.data) {
					// Sort the data array in descending order by "createdAt"
					const sortedUnits = res.data.sort((a, b) => {
						const createdAtA = moment(a.createdAt);
						const createdAtB = moment(b.createdAt);
	
						// Compare timestamps in descending order
						return createdAtB - createdAtA;
					});
					setUnits(sortedUnits);
				}
			},
			setAlertObject
		);
	};

    useEffect(() => {
        handleGetValvesAttributes();
        loggedInUser?.permissions !== USER_PERMISSIONS && handleGetValves();
        handleGetValveTypes();
        handleGetUnits();
    }, [])
    
    const contextData = {
        attributes, 
        setAttributes,
        valves,
        setValves,
        valveTypes, 
        setValveTypes,
        units, 
        setUnits
    }

    return (
        <ValvesContext.Provider value={contextData}>
            {children}
        </ValvesContext.Provider>
    );
};