import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const GetAllUnits = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/valves/units/getUnits`,
	);
};

export const createUnit = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/valves/units/createUnit`,
		data
	);
};

export const editUnit = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/valves/units/updateUnit`,
		data
	);
};

export const deleteUnit = async (data) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/valves/units/deleteUnit`,
		data
	);
};

export const getValvesAttributes = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/valves/attributes/getAttributes`,
	);
};

export const createValveAttribute = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/valves/attributes/createAttribute`,
		data
	);
};

export const createValveAttributeValue = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/valves/attributes/createAttributeValue`,
		data
	);
};


export const editValveAttribute = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/valves/attributes/updateAttribute`,
		data
	);
};

export const deleteValveAttribute = async (data) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/valves/attributes/deleteAttribute`,
		data
	);
};

export const deleteValveAttributeValue = async (data) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/valves/attributes/deleteAttributeValue`,
		data
	);
};

export const getValveTypes = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/valves/types/getValveTypes`,
	);
};

export const getValveTypesBySegment = async (segmentId) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/valves/types/getValveTypesBySegment?id=${segmentId}`,
	);
};


export const createValveType = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/valves/types/createValveType`,
		data
	);
};

export const editValveType = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/valves/types/updateValveType`,
		data
	);
};

export const removeValveType = async (data) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/valves/types/deleteValveType`,
		data
	);
};

export const getValves = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/valves/getValves`,
	);
};

export const getValvesByParams = async (params) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/valves/getValvesByParams?segmentId=${params.segmentId}` + (params.valveTypeId ? `&valveTypeId=${params.valveTypeId}` : '')
	);
};

export const createValve = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/valves/createValve`,
		data
	);
};

export const updateValve = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/valves/updateValve`,
		data
	);
};

export const deleteValve= async (id) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/valves/deleteValve`,
		{id}
	);
};