import { Button, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from '../../styles/AuthenticationStyles';
import supportImg from '../../assets/images/Active Support.gif'; 
import {ReactComponent as LinkedinIcon} from '../../assets/images/icons/linkedin.svg'; 
import {ReactComponent as FacebookIcon} from '../../assets/images/icons/facebook.svg'; 
import {ReactComponent as MailIcon} from '../../assets/images/icons/mail.svg'; 
import { SocialLink } from '../../styles/CustomStyles';
import { useCustomerContext } from '../../contexts/CustomerContext';
import customTranslations from '../../data/staticTranslations.json';
import { useParams, useLocation } from 'react-router-dom';
import { USER_PERMISSIONS } from '../../helpers';

function Support({screen}) {
    const {customerLanguage} = useCustomerContext();
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');

    const handleSendSupportTicket = (e) => {
        e.preventDefault();
    }

    const pageContent = customTranslations.items.filter(item=> item.location === screen).reduce((result, item) => {
        const { item: itemName, name_en, name_es } = item;
        result[itemName] = { name_en, name_es };
        return result;
    }, {})

    return (
        <Stack direction={'row'} justifyContent={'space-between'} sx={{width: '100%', height: 'calc(100vh - max(60px, 6vw))'}}>
            <Stack direction={'column'} gap={1} sx={{width: '50%', pt: 'max(30px, 15vh)'}}>
                <Typography variant={'h2'} component={'h1'}>{pageContent?.title[`name_${customerLanguage.languageCode}`]}</Typography>
                <Typography variant={'h6'} component={'h2'} fontWeight={300} pb={'5vh'}>
                    {pageContent?.sub_title[`name_${customerLanguage.languageCode}`]}
                </Typography>
                <Form 
                    direction={'column'} 
                    onSubmit={handleSendSupportTicket} 
                    autoComplete="off" 
                    gap={'max(20px, 1.4vw)'}
                >
                    <TextField
                        variant="filled"
                        required
                        id="topic"
                        label={pageContent?.input_topic[`name_${customerLanguage.languageCode}`]}
                        color={'secondary'}
                        defaultValue={topic}
                        onChange={(e)=>setTopic(e.target.value)}
                        autoComplete='ViewCrunch'
                    />
                    <TextField
                        variant="filled"
                        required
                        id="message"
                        label={pageContent?.input_message[`name_${customerLanguage.languageCode}`]}
                        color={'secondary'}
                        defaultValue={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        autoComplete='ViewCrunch'
                        rows={5}
                        multiline
                    />
                    <Button
                        variant="contained"
                        color={'secondary'}
                        type={'submit'}
                        fullWidth
                    >
                        {pageContent?.send_button[`name_${customerLanguage.languageCode}`]}
                    </Button>
                </Form>
                <Stack direction={'row'} pt={'5vh'} justifyContent={'center'} alignItems={'center'} gap={'max(16px, 1.5vw)'}>
                    <SocialLink target='_blank' href="mailto:info@aquestia.com">
                        <MailIcon/>
                    </SocialLink>
                    <SocialLink target='_blank' href="https://il.linkedin.com/company/dorot-control-valves">
                        <LinkedinIcon/>
                    </SocialLink>
                    <SocialLink target='_blank' href="https://www.facebook.com/dorot.controlvalves/">
                        <FacebookIcon/>
                    </SocialLink>
                </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'end'} width={'45%'}>
                <img src={supportImg} alt={'Dorot Configurator'}/>
            </Stack>
        </Stack>
    )
}

export default Support