import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  Paper, Slide } from '@mui/material';
import React, {forwardRef, useContext} from 'react';
import Draggable from 'react-draggable';
import { useUIContext } from '../contexts/UIContext';
import theme from '../styles/CustomTheme';

export const DEFAULT_DIALOG_BUTTONS = {ok:"OK", cancel:"CANCEL"};
export const DEFAULT_DIALOG_PARAMETERS = {title:"Title", description:"Description!", content: null};
export const DEFAULT_DIALOG_HANDLERS = {call_on_submit:null, call_on_cancel:null};
export const HIDDEN_DIALOG_OBJECT = {open:false, ...DEFAULT_DIALOG_HANDLERS, ...DEFAULT_DIALOG_BUTTONS, ...DEFAULT_DIALOG_PARAMETERS};

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}
  
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
const dialogStyles = {
	'& .MuiPaper-root': {
		borderRadius: 'max(16px, 1.5vw)',
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 3,
	}
}

const DialogPopupContainer = () => {
  const {dialogObject, setDialogObject} = useUIContext();

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {return;}
    if (reason === 'clickaway') { return; }
    const {call_on_cancel} = dialogObject;
    setDialogObject({...dialogObject, open: false});
    if (call_on_cancel) { call_on_cancel(); }
  };

  const handleSubmit = (event, reason) => {
    event.preventDefault();
    if (reason === 'clickaway') { return; }
    const {call_on_submit} = dialogObject;
    setDialogObject({...dialogObject, open: false});
    if (call_on_submit) { call_on_submit(); }
  };

	return (
		<div>
			<Dialog 
				open={dialogObject.open} 
				onClose={handleClose} 
				TransitionComponent={Transition}
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
				disableEscapeKeyDown
				sx={dialogStyles}
			>
				<DialogTitle sx={{cursor: 'move'}} id="draggable-dialog-title">
					{dialogObject.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{color: theme.palette.gray[900]}}>
						{dialogObject.description}
					</DialogContentText>
					{dialogObject.content}
				</DialogContent>
				{dialogObject.ok || dialogObject.cancel ? (
				<DialogActions>
					<Button onClick={handleSubmit} variant="outlined" color="primary">
						{dialogObject.ok}
					</Button>
					<Button onClick={handleClose} variant="outlined" color="secondary">
						{dialogObject.cancel}
					</Button>
				</DialogActions>
				) : null}
			</Dialog>
		</div>
	);
}

export default DialogPopupContainer;