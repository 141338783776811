import { SERVER_ENDPOINT } from '.';
import {ERROR_ALERT_OBJECT, WARNING_ALERT_OBJECT} from './AlertPopup'

export const handlingAfterFetch = async (fetch, success_handler, setAlertObject) => {
    try {
        const result = await fetch;
        if(result.status === 200){
            if(result.success) {
                if (success_handler) success_handler(result);
            } else {
                setAlertObject && setAlertObject({...ERROR_ALERT_OBJECT, message: result.message})
                success_handler(result)
            }
        }else if (result.status === 401){
            // unautorized
            setAlertObject && setAlertObject({...WARNING_ALERT_OBJECT, severity:'warning', message: result.message})
        }else{
            setAlertObject && setAlertObject({...ERROR_ALERT_OBJECT, message: result.message})
        }
    } catch (error) {
        setAlertObject && setAlertObject({...ERROR_ALERT_OBJECT, message: `Unexpected error: ${error}`})
    }
}

export const getNameById = (id, key, array) => {
    const item = array.find((el) => el.id === id);
    return item ? item[`${key}`] : '';
};

export const capitalizeString = (inputString) => {
    return inputString.replace(/\b\w/g, match => match.toUpperCase());
}

export const getPlaceholderUrl = (imageURL) => {
    const updatedURL = imageURL.replace('/imgs/', '/imgs_sm/');
    return `${SERVER_ENDPOINT}${updatedURL}`;
}

export const getInitials = (fullName) => {
    const initials = fullName
      .split(/\s+/)
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  
    return initials;
}