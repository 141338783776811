import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const getReports = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/reports/getReports`,
	);
};

export const getReportsByParams = async (params) => {
	const filteredParams = Object.fromEntries(
		Object.entries(params).filter(([key, value]) => value) //checking that value exists and truly
	);
	if (filteredParams.countries) filteredParams.countries = filteredParams.countries.join(','); //Convert the 'countries' array to a comma-separated string
	const queryString = new URLSearchParams(filteredParams).toString();

	return await fetchGet(
		`${SERVER_ENDPOINT}/api/reports/getReportsByParams${queryString ? `?${queryString}` : ''}`,
	);
};