import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../contexts/AppContext';
import { Box, Grid, Stack, Typography } from '@mui/material';
import {ReactComponent as HandIcon} from '../../assets/images/waving-hand.svg'; 
import Logout from '../Logout';
import { useReportsContext } from '../../contexts/ReportsContext';
import { useSegmentsContext } from '../../contexts/SegmentsContext';
import UserBarChart from '../charts/UserBarChart';
import theme from '../../styles/CustomTheme';
import moment from 'moment';
import UserAreaChart from '../charts/UserAreaChart';
import { useValvesContext } from '../../contexts/ValvesContext';
import Reports from '../admin_panel/Reports';

const colors = [
    '#596fb5', // Blue
    theme.palette.secondary.dark,
    '#FA8072', // Dark Light Salmon
    '#FFD700', // Dark Light Goldenrod
    '#5F9EA0', // Dark Green Mint
    '#D8BFD8', // Dark Lavender Pink
    '#BDB76B', // Dark Khaki
    '#FFA07A', // Orange
    '#FFDAB9', // Dark Blanched Almond
    '#DAA520', // Dark Pale Goldenrod
    '#8FBC8F', // Dark Pale Green
    '#9370DB', // Dark Lavender
    '#FF69B4', // Dark Pink
    '#6B8E23', // Dark Mint Green
    '#8EE5EE', // Dark Pale Turquoise
];

const getRandomColor = (index) => {
    const normalizedIndex = index % colors.length;
    return colors[normalizedIndex];
};

function ManagerDashboard() {
    const {loggedInUser} = useContext(AppContext);
    const {reports} = useReportsContext();
    const {segments} = useSegmentsContext();
    const {valves} = useValvesContext();
    const [activityAreaChart, setActivityAreaChart] = useState([]);
    const [topValvesBarChart, setTopValvesBarChart] = useState([]);

    const handleBuildAreaChart = () => {
        const startDate = moment().subtract(11, 'months');
      
        // Filter progress records for the last twelve months
        const last12MonthsProgressRecs = reports.reduce((accumulator, report) => {
            const userProgressRecs = report.UserFunnelProgresses.filter((progressRec) => {
                if(
                    (loggedInUser.ManagerAssignment.segmentId && +progressRec.segmentId === +loggedInUser.ManagerAssignment.segmentId) ||
                    !loggedInUser.ManagerAssignment.segmentId
                ){
                    const createdAt = moment(progressRec.createdAt);
                    return createdAt.isSameOrAfter(startDate);
                }
            });
        
            return [...accumulator, ...userProgressRecs];
        }, []);
      
        // Process progress records to build chart data
        const areaChartData = last12MonthsProgressRecs.reduce((accumulator, progressRec) => {
            const hasConfigurationResults = progressRec?.ConfigurationResults?.length > 0;
            const month = moment(progressRec.createdAt).format('MMM YYYY');
        
            if (!accumulator[month]) {
                accumulator[month] = {
                month,
                    completedConfigurationsNumber: 0,
                    incompletedConfigurationsNumber: 0,
                };
            }
        
            // Increment counts based on configuration
            if (hasConfigurationResults) {
                accumulator[month].completedConfigurationsNumber++;
            } else {
                accumulator[month].incompletedConfigurationsNumber++;
            }
      
            return accumulator;
        }, {});
      
        // Sort the chart data based on months
        const sortedAreaChartData = Object.entries(areaChartData)
        .sort(([a], [b]) => moment(a, 'MMM YYYY').valueOf() - moment(b, 'MMM YYYY').valueOf())
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});

        setActivityAreaChart(Object.values(sortedAreaChartData));
    };

    const handleBuildProductsBarChart = () => {
        const valveCounts = {};
        const chartData = [];
    
        const isValveInSegment = (valve) => {
            return loggedInUser.ManagerAssignment.segmentId
                ? valve?.ProductSegments.some(segment => +segment.segmentId === +loggedInUser.ManagerAssignment.segmentId)
                : true;
        };
        
        // const tmp = shuffleArray(reports);
        reports.forEach(progress => {
            progress.UserFunnelProgresses.forEach(progressItem => {
                progressItem.ConfigurationResults.forEach(result => {
                    if (!result.foundValveId) return;                 
                    const foundValve = valves.find(valve => +valve.id === +result.foundValveId);

                    if (isValveInSegment(foundValve)) valveCounts[foundValve.name] = (valveCounts[foundValve.name] || 0) + 1;
                });
            });
        });
    
        Object.keys(valveCounts).forEach((valveName, index) => {
            chartData.push({
                name: valveName,
                value: valveCounts[valveName],
                color: getRandomColor(index),
            });
        });

        setTopValvesBarChart(chartData.slice(0, 10));
    };

    useEffect(() => {
        if(!reports.length || !segments.length) return;
        handleBuildAreaChart();
        if(!valves.length) return;
        handleBuildProductsBarChart();
    }, [reports, segments, valves]) 

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} alignItems={'end'} gap={'10px'}>
                    <Typography component={'h1'} variant={'h3'} fontWeight={800}> 
                        Howdy, {loggedInUser.fullName}!
                    </Typography>
                    <HandIcon style={{width: 'max(30px, 2.8vw)'}}/>
                </Stack>
                <Logout isNavOpen={true} isDashboard={true}/>
            </Stack>
            <Grid sx={{m: 0, mt: 5}} rowGap={'3vh'} justifyContent={'space-between'} container>
                <Grid item width={'100%'}>
                    <Typography variant={'h6'} component={'h3'} fontWeight={700}>
                        Last user's configurations reports
                    </Typography> 
                    <Reports isDashboard={true}/>
                </Grid>
                {activityAreaChart.length > 0 &&
                    <Grid item width={'49%'}>
                        <Typography variant={'h6'} component={'h3'} fontWeight={700} textAlign={'center'}>
                            Monthly Configurations Trends
                        </Typography> 
                        <UserAreaChart 
                            height={'38vh'}
                            data={activityAreaChart}
                            xAxis={
                                {
                                    key: 'month',
                                    name: 'Month'
                                }
                            }
                            yAxis={[
                                {
                                    key: 'completedConfigurationsNumber',
                                    name: `Completed Configurations`
                                },
                                {
                                    key: 'incompletedConfigurationsNumber',
                                    name: `Incompleted Configurations`
                                },
                            ]}
                        />
                    </Grid>
                }
                {topValvesBarChart.length > 0 &&
                    <Grid item width={'49%'}>
                        <Typography variant={'h6'} component={'h3'} fontWeight={700} textAlign={'center'}>
                            Top 10 Configured Valves
                        </Typography> 
                        <UserBarChart 
                            data={topValvesBarChart} 
                            xAxis={
                                {
                                    key: 'name',
                                    name: 'Valve Name'
                                }
                            }
                            yAxis={[
                                {
                                    key: 'value',
                                    name: `Number of times valve was found`
                                },
                            ]}
                            height={'37vh'}
                        />
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

export default ManagerDashboard