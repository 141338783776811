import { Box, Typography } from '@mui/material';
import React from 'react'
import theme from '../../styles/CustomTheme';

const tooltip = {
    backgroundColor: theme.palette.gray[200],
    padding: '20px',
    borderRadius: 'max(14px, 1vw)',
    boxShadow: theme.shadows[0],
}

const CustomChartTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Box sx={tooltip}>
                {label && <Typography sx={{ fontWeight: '700' }}>{label}:</Typography>}
                {payload?.map((pl, key) => 
                    <Typography key={key}>{pl.name}: <b>{pl.value}</b></Typography>
                )}
            </Box>
        )
    }
  
    return null;
};

export default CustomChartTooltip;