import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const getUserProgressRecs = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/progress/getUserProgressRecs`,
	);
};

export const getProgressRec = async (progressId) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/progress/getProgressRec?progressId=${progressId}`,
	);
};

export const createUserProgressRec = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/progress/createProgressRecord`,
		data,
	);
};

export const getQAsByStepId = async (progressId, stepId) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/progress/getProgressQAsByParams?progressId=${progressId}&stepId=${stepId}`,
	);
}


export const createProgressQA = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/progress/createProgressQA`,
		data,
	);
};

export const createResultRec = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/progress/createResultRecord`,
		data,
	);
};
//createResultRecord

export const updateUserProgressRec = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/progress/updateProgressRecord`,
		data,
	);
};

export const updateProgressQA = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/progress/updateProgressQA`,
		data,
	);
};