import './assets/scss/general.scss';
import {
	RouterProvider,
	createBrowserRouter,
	Navigate,
	useLocation,
} from 'react-router-dom';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import AlertPopupContainer, {HIDDEN_ALERT_OBJECT} from './helpers/AlertPopup';
import DialogPopupContainer, {HIDDEN_DIALOG_OBJECT} from './helpers/DialogPopup';
import theme from './styles/CustomTheme';
import { LinearProgress } from '@mui/material';
import { fetchVerifiedUser, getAllUsers } from './api/usersApi';
import { getCompanies } from './api/companiesApi';
import { ADMIN_PERMISSIONS, MANAGER_PERMISSIONS, USER_PERMISSIONS } from './helpers';
import { UIProvider } from './contexts/UIContext';
import { ValvesProvider } from './contexts/ValvesContext';
import AppContext from './contexts/AppContext';
import Loader from './components/Loader';
import { SegmentsProvider } from './contexts/SegmentsContext';
import { getLanguages } from './api/languagesApi';
import { CustomerProvider } from './contexts/CustomerContext';
import ManagerDashboardScreen from './screens/ManagerDashboardScreen';
import { FunnelsProvider } from './contexts/FunnelsContext';
import { ReportsProvider } from './contexts/ReportsContext';

const AuthenticationScreen = lazy(() => import ('./screens/AuthenticationScreen'));
const AdminDashboardScreen = lazy(() => import ('./screens/AdminDashboardScreen'));
const UserDashboardScreen = lazy(() => import ('./screens/UserDashboardScreen'));
const ErrorScreen = lazy(() => import ('./screens/ErrorScreen'));
const Home = lazy(() => import ('./components/Home'));

function App() {

	const [loggedInUser, setLoggedInUser] = useState({});
	const [users, setUsers] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [languages, setLanguages] = useState([]);

	const ProtectedRoute = ({ children }) => {
		const location = useLocation();
		let subroute = '';

		//pass subroute route only for dashboards
		if (location.pathname.includes('dashboard/')) {
			subroute = location.pathname.split('dashboard/')[1] || '';
		} 

		if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
			return <Navigate to='/auth/sign-in' state={{subroute}}/>;
		}
		
		return <ValvesProvider> {children} </ValvesProvider>
	};

	const verifyUserSessionIsActive = async () => {
		try {
			const verifiedUser = await fetchVerifiedUser();
			if (verifiedUser.success && verifiedUser.data.email) {
				let navigateURL;
				if(verifiedUser.data.permissions === ADMIN_PERMISSIONS) {navigateURL = '/admin-dashboard'}
				else if(verifiedUser.data.permissions === USER_PERMISSIONS) {navigateURL = '/dashboard'}
				else if(verifiedUser.data.permissions === MANAGER_PERMISSIONS) {navigateURL = '/manager-dashboard'}
				setLoggedInUser({ ...verifiedUser.data });
				return <Navigate to={navigateURL} />
			} 
		} catch (error) {
			// console.log('error:', error);
		}
	};

	const handleGetUsers = async() => {
		const res = await getAllUsers();
		if (res.success && res.data) {
			setUsers([...res.data]);
		}
	}

	const handleGetLanguages = async() => {
		const res = await getLanguages();
		if (res.success && res.data) {
			setLanguages([...res.data]);
		}
	}

    const handleGetCompanies = async() => {
		const res = await getCompanies();
		if (res.success && res.data) {
			setCompanies([...res.data]);
		}
	}

	const router = createBrowserRouter([
		{
			path: '/',
			element:
				<Suspense fallback={<Loader />}>
					<Home />
				</Suspense>
			,
			errorElement: <ErrorScreen />,
		},
		{
			path: '/auth/:view?',
			element: 
				<Suspense fallback={<Loader />}>
					<AuthenticationScreen />
				</Suspense>,
			errorElement: <ErrorScreen />,
		},
		{
			path: '/admin-dashboard/:screen?',
			element: (
				<ProtectedRoute>
					<Suspense fallback={<Loader />}>
						<AdminDashboardScreen/>
					</Suspense>
				</ProtectedRoute>
			),
			errorElement: <ErrorScreen />,
		},
		{
			path: '/dashboard/:screen?',
			element: (
				<ProtectedRoute>
					<Suspense fallback={<Loader />}>
						<CustomerProvider>
							<UserDashboardScreen/>
						</CustomerProvider>
					</Suspense>
				</ProtectedRoute>
			),
			errorElement: <ErrorScreen />,
		},
		{
			path: '/manager-dashboard/:screen?',
			element: (
				<ProtectedRoute>
					<Suspense fallback={<Loader />}>
						<FunnelsProvider>
							<ReportsProvider>
								<ManagerDashboardScreen/>
							</ReportsProvider>
						</FunnelsProvider>
					</Suspense>
				</ProtectedRoute>
			),
			errorElement: <ErrorScreen />,
		},
	]);

	const contextData = {
        loggedInUser,
        setLoggedInUser,
        users,
        setUsers,
        companies,
        setCompanies,
		languages, 
		setLanguages
    }

	useEffect(() => {
		verifyUserSessionIsActive();
		handleGetCompanies();
	}, []);

	useEffect(() => {
		loggedInUser?.id && handleGetLanguages();
        loggedInUser?.permissions === ADMIN_PERMISSIONS && handleGetUsers();
		
    }, [loggedInUser])
	
	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				<AppContext.Provider value={contextData}>
					<UIProvider>
						<SegmentsProvider>
							<RouterProvider router={router} />
							<AlertPopupContainer />
							<DialogPopupContainer />
						</SegmentsProvider>
					</UIProvider>
				</AppContext.Provider>
			</ThemeProvider>
		</div>
	);
}

export default App;
