import { Typography } from '@mui/material'
import React from 'react'

function CustomTick({ x, y, payload, rotate }) {
    return (
        <g transform={`translate(${x},${y})`}>
            <Typography sx={{transform: rotate ? `rotate(${rotate})` : '0deg', zIndex: 9999}} component="text" x={0} y={0} dy={16} textAnchor="end" fill="#666">
                {payload.value}
            </Typography>
        </g>
    )
}

export default CustomTick