import { Box, createTheme, experimental_sx as sx } from '@mui/material/styles';
// Supports weights 300-900
import '@fontsource-variable/montserrat';

const customPalette = {
    primary: {
        main: '#213266',
        light: '#2b407f',
        dark: '#19264f',
        contrastText: "#fcfcfc"
    },
    secondary: {
        main: '#09c3aa',
        light: '#0bd4b9',
        dark: '#07b8a0',
        contrastText: "#0b1124"
    },
    gray: {
        50: '#ffffff4e', 
        100: '#fcfcfc',
        200: '#f3f7fd', 
        300: '#e1e7f0',
        400: '#C5CCD9',
        500: '#b0bbc9',
        600: '#7B828F',
        800: '#101833',
        900: '#101b35',
    },
}

const theme = createTheme({
    palette: customPalette,
    typography: {
        fontFamily: ['Montserrat Variable, sans-serif'],
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightSemibold: 600,
        fontWeightBold: 700,
        h1: {
            fontSize: 'max(34px, 3.6vw)',
            fontWeight: 800,
            color: customPalette.gray[800]
        },
        h2: {
            fontSize: 'max(28px, 2.4vw)',
            fontWeight: 800,
            color: customPalette.gray[800]
        },
        h3: {
            fontSize: 'max(22px, 2vw)',
            fontWeight: 700,
            color: customPalette.gray[800]
        },
        h4: {
            fontSize: 'max(20px, 1.5vw)',
            fontWeight: 600,
            color: customPalette.gray[800]
        },
        h5: {
            fontSize: 'max(18px, 1.3vw)',
            fontWeight: 700,
            color: customPalette.secondary.contrastText
        },
        h6: {
            color: customPalette.gray[800]
        },
        p: {
            fontSize: 'max(16px, 1vw)',
            fontWeight: 400,
            fontFamily: ['Montserrat Variable, sans-serif'],
            color: customPalette.gray[800]
        },
        p_sm: {
            fontSize: 'max(16px, 0.8vw)',
            fontWeight: 400,
            fontFamily: ['Montserrat Variable, sans-serif'],
            color: customPalette.gray[800]
        },
        input: {
            fontSize: 'max(16px, 1vw)',
            color: customPalette.gray[800]
        },
        label: {
            fontSize: 'max(16px, 1vw)',
        },
        button: {
            fontSize: 'max(18px, 1.1vw)',
        }
    }, 
    components: {
        MuiTextField: {
            defaultProps: {
                InputProps: {
                    disableUnderline: true,
                },
            },
        },
        MuiInputBase:{
            styleOverrides: {
                root: {
                    background: 'rgba(33, 50, 180, 0.1)',
                    borderRadius: 'max(14px, 1vw)',
                    fontSize: 'max(16px, 1vw)',
                },
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 'max(16px, 1vw)',
                },
            },
        },
        MuiFilledInput:{
            styleOverrides: {
                root: {
                    background: 'rgba(33, 50, 180, 0.1)',
                    borderRadius: 'max(14px, 1vw)',
                },
                colorSecondary: {
                    background: 'rgba(8, 134, 117, 0.1)',
                    color: customPalette.gray[900],
                    '&:hover, &.Mui-focused':{
                        background: 'rgba(8, 134, 117, 0.13)'
                    }
                },
            }
        },
        MuiOutlinedInput:{
            styleOverrides: {
                root: {
                    background: 'rgba(33, 50, 180, 0.1)',
                    borderRadius: 'max(14px, 1vw)',
                    // '& label': {
                    //     transform: 'translate(14px, 10px) scale(1)',
                    // }
                },
                colorSecondary: {
                    background: 'rgba(8, 134, 117, 0.1)',
                    color: customPalette.gray[900],
                    '&.Mui-disabled':{
                        background: 'rgba(0, 0, 0, 0.15)'
                    }
                },
                // label: {
                //     transform: 'translate(14px, 10px) scale(1)',
                // }
            }
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    // borderRadius: 'max(14px, 0.9vw)'
                    borderRadius: 'max(9px, 0.5vw)',
                },
                contained: {
                    borderRadius: 'max(14px, 0.9vw)',                   
                    fontWeight: 300,
                },
                outlinedPrimary: {
                    background: 'rgba(232, 234, 255, 0.7)',
                    '&:hover': {
                        background: 'rgba(232, 234, 255, 1)',
                    }
                },
                outlinedSecondary: {
                    background: 'rgba(246, 255, 253, 0.7)',
                    border: '1px solid #069985',
                    '&:hover': {
                        background: 'rgba(246, 255, 253, 1)',
                        border: '1px solid #076e5f',
                    }
                },
                
            }
        },    
        MuiMenu: {
            styleOverrides: {
                paper: {
                    background: 'rgb(232, 234, 247)',
                    borderRadius: 'max(14px, 1vw)',
                    border: `2px solid ${customPalette.primary.main} `
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    background: customPalette.secondary.dark,
                    margin: '0 !important',
                    borderRadius: 'max(10px, 0.8vw) !important',
                    '&::before': {
                        content: 'unset',
                    }
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    background: customPalette.secondary.dark,
                    borderRadius: 'max(10px, 0.8vw) !important',
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    background: customPalette.gray[200],
                    borderBottomLeftRadius: 'max(10px, 0.8vw)',
                    borderBottomRightRadius: 'max(10px, 0.8vw)',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '15px', 
                    color: customPalette.gray[900],
                    marginTop: 0,
                    marginInlineStart: '4px'
                },
            },
        },
        MuiPopper:{
            styleOverrides: {
                root: {
                    transitionProperty: 'opacity !important',
                },
            }
        },
    },
    shadows:  Array.from({ length: 25 }, () => 'rgba(0, 0, 0, 0.15) 0px 0px 14px'),
});



export default theme;
