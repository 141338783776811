import React, { useState, useContext, useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useCustomerContext } from '../contexts/CustomerContext';
import AppContext from '../contexts/AppContext';
import theme from '../styles/CustomTheme';
import VerificationInput from 'react-verification-input';
import { ChangeEmailVerify, ChangeNewEmailVerify, ChangeUserEmail } from '../api/usersApi';
import { handlingAfterFetch } from '../helpers/Tools';
import { useUIContext } from '../contexts/UIContext';
import Loader from './Loader';
import { SUCCESS_ALERT_OBJECT } from '../helpers/AlertPopup';

const verificationInputStyles = {
    '& *':{
        fontFamily: 'Montserrat Variable',
    },
    '& .vi__wrapper': {
        marginInline: 'auto',
        '& .vi__container': {
            gap: 'max(10px, 1.4vw)',
            width: '100%',
            '& .vi__character': {
                borderRadius: '8px',
                minWidth: '50px',
                color: theme.palette.primary.main,
                background: 'rgba(43, 64, 127, 0.15)',
                border: 'none',
                outline: `2px solid transparent`
            },
            '& .vi__character--selected': {
                outline: `2px solid ${theme.palette.primary.light}`,
            }
        }
    }
}

function ChangeEmailModal({content, email, setIsModalOpen}) {
    const {loggedInUser, setLoggedInUser} = useContext(AppContext);
    const {setAlertObject} = useUIContext();
    const {customerLanguage} = useCustomerContext();
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [emailToken, setEmailToken] = useState('');

    const handleSendVerificationMail = async() => {
        await handlingAfterFetch( 
            ChangeEmailVerify(), 
            (res) => {
                // console.log('res', res);
                if(res?.success) {
                    setIsLoading(false);
                }
            },
            setAlertObject,
        ); 
    }

    const handleSendVerificationMailToNewAddress = async() => {
        setIsLoading(true);
        await handlingAfterFetch( 
            ChangeNewEmailVerify({emailToken, newEmail: email}),
            (res) => {
                if(res?.success) {
                    setStep(1);
                }
            },
            setAlertObject,
        ); 
        setIsLoading(false);
    }

    const handleChangeUserEmail = async() => {
        setIsLoading(true);
        await handlingAfterFetch( 
            ChangeUserEmail({emailToken, newEmail: email}),
            (res) => {
                if(res?.success && res?.data) {
                    setIsModalOpen(false);
                    setLoggedInUser({...loggedInUser, email});
                    setAlertObject({...SUCCESS_ALERT_OBJECT, message: `Email Address has been successfully changed!`});
                }
            },
            setAlertObject,
        ); 
        setIsLoading(false);
    }

    useEffect(() => {
        handleSendVerificationMail();
    }, [])
    

    return (
        <Stack gap={'max(20px, 1.2vw)'} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
            {isLoading ? <Loader/> 
                :
                <>
                    {step === 0 &&
                        <>               
                            <Typography variant={'h5'} component={'h3'} textAlign={'center'}>
                                {content.steps.step_1.title[`name_${customerLanguage.languageCode}`]}: {` `}
                                <u>{loggedInUser.email}</u>
                            </Typography>
                            <Box sx={verificationInputStyles}>
                                <VerificationInput
                                    onChange={setEmailToken}
                                    inputField={{
                                        onChange: (e) => {
                                            setEmailToken(e.nativeEvent.target.value);
                                        },
                                    }}
                                    // passwordMode={true}
                                    placeholder={' '}
                                    length={6}
                                    validChars="0-9"
                                    removeDefaultStyles
                                    container={{
                                        className: 'container',
                                    }}
                                    characters={{
                                        className: 'characters',
                                    }}
                                    character={{
                                        className: 'character',
                                        classNameInactive: 'character--inactive',
                                        classNameSelected: 'character--selected',
                                    }}
                                />
                            </Box>
                            <Box sx={{minWidth: '80%'}}>
                                <Button
                                    variant="contained"
                                    color={'secondary'}
                                    onClick={handleSendVerificationMailToNewAddress}
                                    fullWidth
                                >
                                    SEND
                                </Button>
                                <Typography textAlign={'center'} mt={1}>This code will expire after 5 minutes</Typography>
                            </Box>
                        </>
                    }
                    {step === 1 &&
                        <>   
                            <Typography variant={'h6'} component={'h6'} textAlign={'center'} color={theme.palette.success.dark}>Verified successfully!</Typography>
                            <Typography variant={'h5'} component={'h3'} textAlign={'center'}>
                                {content.steps.step_1.title[`name_${customerLanguage.languageCode}`]}: {` `}
                                <u>{email}</u>
                            </Typography>
                            <Box sx={verificationInputStyles}>
                                <VerificationInput
                                    onChange={setEmailToken}
                                    inputField={{
                                        onChange: (e) => {
                                            setEmailToken(e.nativeEvent.target.value);
                                        },
                                    }}
                                    placeholder={' '}
                                    length={6}
                                    validChars="0-9"
                                    removeDefaultStyles
                                    container={{
                                        className: 'container',
                                    }}
                                    characters={{
                                        className: 'characters',
                                    }}
                                    character={{
                                        className: 'character',
                                        classNameInactive: 'character--inactive',
                                        classNameSelected: 'character--selected',
                                    }}
                                />
                            </Box>
                            <Box sx={{minWidth: '80%'}}>
                                <Button
                                    variant="contained"
                                    color={'secondary'}
                                    onClick={handleChangeUserEmail}
                                    fullWidth
                                >
                                    CHANGE EMAIL
                                </Button>
                                <Typography textAlign={'center'} mt={1}>This code will expire after 5 minutes</Typography>
                            </Box>
                        </>
                    }
                </>
            }
        </Stack>
    )
}

export default ChangeEmailModal