import React, { createContext, useContext, useEffect, useState } from 'react';
import { getFunnels } from '../api/funnelsApi';
import { useUIContext } from './UIContext';
import { handlingAfterFetch } from '../helpers/Tools';


const FunnelsContext = createContext();

export const useFunnelsContext = () => useContext(FunnelsContext);

export const FunnelsProvider = ({ children }) => {
    const {setAlertObject} = useUIContext();
    const [funnels, setFunnels] = useState([]);
    const [languageId, setLanguageId] = useState(1); //for translations editing

    const handleGetFunnels = async() => {
        await handlingAfterFetch( 
            getFunnels(), 
            (res) => {
                if(res?.success && res?.data) {
                    setFunnels(res.data);
                }
            },
            setAlertObject,
        );
    }

    const handleChangeTranslationData = (value, key, entityId, tableName, state, stateSetter) => {
        const prevTranslationsData = [...state];
        const objIndex = prevTranslationsData.findIndex(item=>+item.languageId  === +languageId && item.attributeKey === key);
        if(objIndex === -1) {
            //if not found - new translation obj
            prevTranslationsData.push({
                isNew: true,
                entityId: entityId || null,
                tableName,
                languageId,
                attributeKey: key,
                attributeValue: value
            })
        } else{
            prevTranslationsData[objIndex] = {
                ...prevTranslationsData[objIndex],
                attributeValue: value
            }
        }
        stateSetter(prevTranslationsData);
    }

    useEffect(() => {
        handleGetFunnels()
    }, [])
    
    const contextData = {
        funnels, 
        setFunnels,
        languageId, 
        setLanguageId,
        handleChangeTranslationData
    }

    return (
        <FunnelsContext.Provider value={contextData}>
            {children}
        </FunnelsContext.Provider>
    );
};