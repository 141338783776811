import styled from '@emotion/styled';
import imageSrc from '../assets/images/signin.png'; 
import theme from './CustomTheme'


export const AuthContainerStyle = {
    height: '100vh',
    background: theme.palette.primary.main,
    '.wave': {
        backgroundImage: `url(${imageSrc})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '17%'
    }
}

export const signInWrapper = {
    '.login': {
        background: theme.palette.gray[200],
        height: '100%',
        width: '100%',
        paddingBlock: 'max(30px, 5vh)',
    },
}

export const signUpWrapper = {
    '.signup': {
        height: '100%',
        width: '100%',
        paddingBlock: 'max(30px, 5vh)',
    },
}

export const Form = styled("form")(({ theme, width, direction, gap }) => ({
    width: width || '100%',
    // height: '100%',
    display: 'flex',
    flexDirection: direction || 'column',
    gap: gap || 'max(20px, 2vw)',
    flexWrap: 'wrap',
    justifyContent: direction === 'row' ? 'space-between' : null
}));