export const UnitConversions = {
    // pressure
    barTOatm: (bar) => bar / 1.01325,
    atmTObar: (atm) => atm * 1.01325,
    barTOinchOfWater: (bar) => bar * 406.84,
    inchOfWaterTObar: (inchOfWater) => inchOfWater / 406.84,
    barTOmwc: (bar) => bar * 10.197,
    mwcTObar: (mwc) => mwc / 10.197,
    barTOmpa: (bar) => bar * 0.1,
    mpaTObar: (mpa) => mpa / 0.1,
    barTOkpa: (bar) => bar * 100,
    kpaTObar: (kpa) => kpa / 100,
    barTOpa: (bar) => bar * 100000,
    paTObar: (pa) => pa / 100000,
    barTOpsi: (bar) => bar * 14.5038,
    psiTObar: (psi) => psi / 14.5038,
    // volume
    m3TOliter: (m3) => m3 * 1000,
    literTOm3: (liter) => liter / 1000,
    m3TOgallon: (m3) => m3 * 264.172,
    gallonTOm3: (gallon) => gallon / 264.172,
    // flow
    m3hrTOgpm: (m3hr) => m3hr * 15850.3,
    gpmTOm3hr: (gpm) => gpm / 15850.3,
    m3hrTOmgd: (m3hr) => m3hr * 0.64632,
    mgdTOm3hr: (mgd) => mgd / 0.64632,
    m3hrTOmld: (m3hr) => m3hr * 1000000,
    mldTOm3hr: (mld) => mld / 1000000,
    m3hrTOcfs: (m3hr) => m3hr * 35.315,
    cfsTOm3hr: (cfs) => cfs / 35.315,
    m3hrTOcfm: (m3hr) => m3hr * 2118.88,
    cfmTOm3hr: (cfm) => cfm / 2118.88,
    m3hrTOlps: (m3hr) => m3hr * 1000,
    lpsTOm3hr: (lps) => lps / 1000,
    m3hrTOlpm: (m3hr) => m3hr * 16.6667,
    lpmTOm3hr: (lpm) => lpm / 16.6667,
    // end connections
    universalTOjis: (universal) => universal * 25.4,
    jisTOuniversal: (jis) => jis / 25.4,
    universalTOansi300: (universal) => universal * 12,
    ansi300TOuniversal: (ansi300) => ansi300 / 12,
    universalTOgrooved: (universal) => universal * 25.4,
    groovedTOuniversal: (grooved) => grooved / 25.4,
    universalTOnpt: (universal) => universal * 25.4,
    nptTOuniversal: (npt) => npt / 25.4,
    universalTObsp: (universal) => universal * 25.4,
    bspTOuniversal: (bsp) => bsp / 25.4,
    universalTOansi125: (universal) => universal * 8,
    ansi125TOuniversal: (ansi125) => ansi125 / 8,
    universalTOisoPn10: (universal) => universal * 25.4,
    isoPn10TOuniversal: (isoPn10) => isoPn10 / 25.4,
    universalTOasdt: (universal) => universal * 25.4,
    asdtTOuniversal: (asdt) => asdt / 25.4,
    universalTOisoPn16: (universal) => universal * 25.4,
    isoPn16TOuniversal: (isoPn16) => isoPn16 / 25.4,
    universalTOansi150: (universal) => universal * 6,
    ansi150TOuniversal: (ansi150) => ansi150 / 6,
    //viscosity
    ssuTOcentistokes: (ssu) => {
        return Math.pow(10, (1.271 * Math.log10(ssu) - 0.5145));
    },
    centistokesTOssu: (centistokes) => {
        return Math.pow(10, ((Math.log10(centistokes) + 0.5145) / 1.271));
    },
    //degrees
    centigradeTOfahrenheit: (centigrade) => {
        return (centigrade * 9/5) + 32;
    },
    fahrenheitTOcentigrade: (fahrenheit) => {
        return (fahrenheit - 32) * 5/9;
    },
    cmTOinch: (cm) => {
        return cm / 2.54;
    },
    inchTOcm: (inch) => {
        return inch * 2.54;
    },
    //no conversion 
    acTOdc: (ac) => {
        return ac;
    },
    dcTOac: (dc) => {
        return dc;
    },
};