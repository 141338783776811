import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const getCompanies = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/companies/getCompanies`,
	);
};

export const createCompany = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/companies/createCompany`,
		data,
	);
};

export const updateCompany = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/companies/updateCompany`,
		data,
	);
};

export const deleteCompany = async (data) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/companies/deleteCompany`,
		data,
	);
};

