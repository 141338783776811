import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { getNameById } from '../../../helpers/Tools'
import AppContext from '../../../contexts/AppContext';
import moment from 'moment';
import { useSegmentsContext } from '../../../contexts/SegmentsContext';
import { segmentItemSm } from '../../../styles/SegmentsStyle';

function UserDetailsData({reportData}) {
    const {companies, languages} = useContext(AppContext);
    const {segments} = useSegmentsContext();

    return (
        <>
            <Typography component={'h2'} variant={'h4'} fontWeight={800} mb={3}>
                User Details: 
            </Typography>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexWrap={'wrap'}
                gap={'10px'} 
                sx={{paddingInlineStart: '16px'}}
            >
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Joined: {` `}
                    <u style={{fontWeight: 500}}>
                        {moment(reportData.createdAt).format('D MMMM, YYYY')}
                    </u>
                </Typography>
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Full Name: <u style={{fontWeight: 500}}>{reportData?.fullName}</u>
                </Typography>
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Email: <u style={{fontWeight: 500}}>{reportData?.email}</u>
                </Typography>
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Region: <u style={{fontWeight: 500}}>{reportData?.region}</u>
                </Typography>
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Country: <u style={{fontWeight: 500}}>{reportData?.country}</u>
                </Typography>
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Address: <u style={{fontWeight: 500}}>{reportData?.address}</u>
                </Typography>
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Phone: <u style={{fontWeight: 500}}>{reportData?.phone}</u>
                </Typography>
                <Typography component={'p'} variant={'p'} width={'48%'}>
                    Company: <u style={{fontWeight: 500}}>{
                        reportData.companyId ? 
                        getNameById(reportData.companyId, 'companyName', companies)
                        :
                        'OTHER'
                    }</u>
                </Typography>
                <Stack direction={'row'} gap={'5px'} width={'48%'}>
                    <Typography component={'p'} variant={'p'}>
                        Preferred Segment(s): 
                    </Typography>
                    <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'center'} gap={'10px'}>
                        {reportData.segments?.map(segId => (
                            <Typography component={'span'} variant={'p_sm'} sx={{...segmentItemSm, fontWeight: 600}}>
                                {getNameById(segId, 'name', segments)}
                            </Typography>
                        ))}
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={'10px'} width={'48%'}>
                    <Typography component={'p'} variant={'p'}>
                        Preferred Language: 
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <img 
                            width={20}
                            src={require(`../../../assets/images/flags/${getNameById(reportData?.languageId, 'languageCode', languages)}.png`)}
                            alt={getNameById(reportData?.languageId, 'languageCode', languages)} 
                        />
                        <Typography component={'p'} variant={'p'} fontWeight={500}>
                            {getNameById(reportData?.languageId, 'languageName', languages)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default UserDetailsData