import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAllSegments } from '../api/segmentsApi';
import { getValveTypesBySegment} from '../api/valvesApi';
import { handlingAfterFetch } from '../helpers/Tools';
import { useUIContext } from './UIContext';

const SegmentsContext = createContext();

export const useSegmentsContext = () => useContext(SegmentsContext);

export const SegmentsProvider = ({ children }) => {
    const {setAlertObject} = useUIContext();
    const [segments, setSegments] = useState([]); 

    const handleGetSegments = async () => {
        await handlingAfterFetch(
            getAllSegments(),
            async (res) => {
                if (res.success && res.data) {
                    const newSegments = await Promise.all(
                        res.data.map(async (segment) => {
                            const newSegment = { ...segment, segmentValveTypes: [] };
                            const valveTypesRes = await getValveTypesBySegment(segment.id);
                            if (valveTypesRes.success && valveTypesRes.data) {
                                newSegment.segmentValveTypes = [...valveTypesRes.data];
                            }
                            return newSegment;
                        })
                    );
                    setSegments(newSegments);
                }
            },
            setAlertObject
        );
    }

    useEffect(() => {
        handleGetSegments();
    }, [])
    
    const contextData = {
        segments, 
        setSegments,
    }

    return (
        <SegmentsContext.Provider value={contextData}>
            {children}
        </SegmentsContext.Provider>
    );
};