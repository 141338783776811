import { Grid, Paper } from "@mui/material";
import styled from '@emotion/styled';
import theme from './CustomTheme'

export const SegmentsWrapper = {
    width: '100%',
    height: '100%',
}

export const SegmentItem = styled(Grid)(({ theme, basis, height }) => ({
    padding: '0 !important',
    boxShadow: theme.shadows.main,
    borderRadius: 'max(16px, 1vw)',
    textAlign: 'center',
    height: height || '37vh',
    flexBasis: `${basis || '45%'} !important`,
    overflow: 'hidden',
    cursor: 'pointer',
    'span':{
        height: '100%',
        width: '100%',
        'img':{
            transition: 'all ease-out 300ms !important'
        }
    },
    '&:hover': {
        'img':{
            transform: 'scale(1.05)',
            filter: 'grayscale(0%)'
        }
    },
    'img':{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        filter: 'grayscale(30%)'
    },
    '.title': {
        bottom: '20px',
        right: '20px',
        background: theme.palette.gray[100],
        color: theme.palette.gray[900],
        padding: '8px 20px',
        borderRadius: 'max(10px, 0.7vw)',
        boxShadow: theme.shadows.main,
    }
}));

export const segmentItemSm = {
    padding: '4px 10px',
    background: theme.palette.gray[400],
    borderRadius: '14px'
}