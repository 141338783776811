import { SERVER_ENDPOINT } from '../helpers';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
} from './index';

export const getAllSegments = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/segments/getSegments`,
	);
};

export const updateSegment = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/segments/updateSegment`,
		data
	);
};
